import { BeamWordmarkIcon } from "../../Icons";
import React from "react";
import styled, { keyframes } from "styled-components";

const GRADIENTS = [
  {
    id: 1,
    gradient:
      "linear-gradient(180.03deg, #BBFFCA 52.5%, #48DD13 89.08%, #008805 119.28%)",
  },
  {
    id: 2,
    gradient:
      "linear-gradient(180.03deg, #F1E869 52.5%, #FEA514 89.08%, #FF4539 119.28%)",
  },
  {
    id: 3,
    gradient:
      "linear-gradient(180.03deg, #FF6A9A 52.5%, #FF5544 89.08%, #FF4539 119.28%)",
  },
  {
    id: 4,
    gradient:
      "linear-gradient(180.03deg, #BBDBFF 52.5%, #139EDD 89.08%, #B9F7EA 119.28%)",
  },
].reverse();

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const FlickerAnimation = keyframes`
    18%, 22%, 25%, 53%, 57%, 100% {
    opacity: 1;
    /* box-shadow: 0px 0px 1px #05ce31, 0px 0px 4px #00ff38; */
  }
  20%, 24%, 55% { 
    opacity: 0.6;
    /* box-shadow: none; */
  }
  28%, 48% { 
    opacity: 0.85;
    /* box-shadow: 0px 0px 2px #05ce31, 0px 0px 2px #00ff38; */
  }
  0%, 60%, 70% { 
    opacity: 0.3;
    /* box-shadow: none; */
  }
`;

export const LedLight = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #f8ffff;
  box-shadow: 0px 0px 1px #05ce31, 0px 0px 4px #00ff38;
  animation: ${FlickerAnimation} 5s;
`;

export const GradientStretch = styled.div`
  margin-top: 2.5px;
  height: 4px;
  width: 100%;
  margin-left: -2px;
  z-index: 0;
`;

export const Gradient = styled.div<{ $gradient: string; id: number }>`
  background: ${({ $gradient }) => $gradient};
  height: 25%;

  animation: ${FlickerAnimation} ${({ id }) => id}s;
`;
export const IconWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  padding-top: 0.5px;
`;

export const StyledBeamWordmarkIcon = styled(BeamWordmarkIcon)`
  z-index: 1;
`;

export const Caption = styled.div`
  font-family: "SuisseIntl";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  color: #575757;
`;

interface Props {
  className?: string;
}

export const Footer = ({ className }: Props) => {
  return (
    <div className={className}>
      <Container>
        <LedLight />

        <Caption>powered by</Caption>

        <IconWrapper>
          <StyledBeamWordmarkIcon />

          <GradientStretch>
            {GRADIENTS.map(({ id, gradient }) => (
              <Gradient key={id} $gradient={gradient} id={id} />
            ))}
          </GradientStretch>
        </IconWrapper>
      </Container>
    </div>
  );
};
