import { Link, useHistory, useLocation } from "react-router-dom";
import { BeamSwapLogoIcon } from "../Icons";
import { Pill } from "../Pill";
import Row, { RowBetween } from "../Row";
import Settings from "../Settings";
import { Text } from "rebass";
import styled from "styled-components";
import { ConnectButton } from "../ConnectButton";
import { ReactComponent as ChevronDown } from "../../assets/images/chevron-down.svg";
import { ChangeEventHandler, useEffect, useState } from "react";

const HeaderFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  top: 0;
  position: absolute;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 12px 0 0 0;
    width: calc(100%);
    position: relative;
    margin-bottom: 48px;
  `};
`;

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
     div {
      display: none;
     }
  `};
`;

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: flex-end;
    gap: 4px;
  `};
`;

const ConnectWalletButton = styled(Pill).attrs({
  $isSelected: true,
})`
  min-width: fit-content;
  display: flex;
  gap: 4px;
  width: fit-content;
  padding-left: 16px;
  padding-right: 16px;
  white-space: nowrap;
`;

const HeaderNavigation = styled.div`
  display: flex;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  gap: 8px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
    position: relative;
    left: unset;
    transform: unset;
    gap: 8px;
  `};
`;

const HideDesktop = styled.div`
  display: none;
  margin-left: 10px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: flex;
    gap: 4px;
  `};
`;

const PillSelect = styled(Pill).attrs({
  as: "div",
  $isSelected: true,
})`
  position: relative;
  width: 90px;
`;

const StyledChevron = styled.div`
  position: absolute;
  top: 45%;
  right: 15px;
  transform: translateY(-50%);
  pointer-events: none;
`;

const Select = styled.select`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-left: 15px;
  appearance: none;
  background: none;
  border: none;
  color: inherit;
  font-size: inherit;
`;

const selectNavigationItems = [
  {
    label: "swap",
    value: "/swap",
  },
  {
    label: "pool",
    value: "/pool",
  },
  {
    label: "bridge",
    value: "https://bridge.onbeam.com/bridge",
    external: true,
  },
];

export default function Header() {
  const location = useLocation();
  const history = useHistory();
  const [selectValue, setSelectValue] = useState<string>(location.pathname);

  useEffect(() => {
    const itemIndex = selectNavigationItems.findIndex(
      (item) => item.value === location.pathname
    );
    if (itemIndex !== -1) {
      setSelectValue(location.pathname);
    }
  }, [location.pathname]);

  const onSelectChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    const item = selectNavigationItems.find(
      (item) => item.value === e.target.value
    );
    if (!item) return;
    if (!item.external) {
      history.push(item.value);
    } else {
      window.open(item.value, "_blank", "noopener noreferrer");
    }
  };

  return (
    <HeaderFrame>
      <RowBetween
        style={{ alignItems: "flex-start" }}
        $padding="1rem 1rem 0 1rem"
      >
        <Row style={{ gap: "5px" }}>
          <Link to="/">
            <HeaderElement>
              <BeamSwapLogoIcon />
              <Text color="#fff" fontSize="20px">
                swap
              </Text>
            </HeaderElement>
          </Link>

          <HideDesktop>
            <PillSelect>
              <Select
                role="listbox"
                aria-label="Navigation"
                onChange={onSelectChange}
                value={selectValue}
              >
                {selectNavigationItems.map((item) => (
                  <option
                    key={item.value}
                    value={item.value}
                    aria-selected={location.pathname === item.value}
                  >
                    {item.label}
                  </option>
                ))}
              </Select>
              <StyledChevron>
                <ChevronDown />
              </StyledChevron>
            </PillSelect>
          </HideDesktop>
        </Row>

        <HeaderNavigation>
          {selectNavigationItems.map((item) =>
            item.external ? (
              <a
                key={item.value}
                href={item.value}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Pill>{item.label}</Pill>
              </a>
            ) : (
              <Link key={item.value} to={item.value}>
                <Pill $isSelected={location.pathname === item.value}>
                  {item.label}
                </Pill>
              </Link>
            )
          )}
        </HeaderNavigation>

        <HeaderControls>
          <ConnectButton buttonComponent={ConnectWalletButton} />
          <Settings />
        </HeaderControls>
      </RowBetween>
    </HeaderFrame>
  );
}
