import * as Dialog from "@radix-ui/react-dialog";
import React from "react";
import { isMobile } from "react-device-detect";
import { animated, useTransition, useSpring } from "react-spring";
import { useGesture } from "react-use-gesture";
import styled, { css } from "styled-components";
import { Applet } from "../Applet";

const AnimatedDialogOverlay = animated(Dialog.Overlay);

const StyledDialogOverlay = styled(AnimatedDialogOverlay)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.modalBG};
`;

const AnimatedDialogContent = animated(Dialog.Content);

const StyledDialogContent = styled(AnimatedDialogContent)<{
  $maxHeight: ModalProps["maxHeight"];
  $minHeight: ModalProps["minHeight"];
  $isMobile: boolean;
}>`
  padding: 0px;
  width: 50vw;
  overflow: hidden;
  background: none;
  border-radius: 20px;
  align-self: ${({ $isMobile }) => ($isMobile ? "flex-end" : "center")};
  max-width: 420px;

  ${({ $maxHeight }) =>
    $maxHeight &&
    css`
      max-height: ${$maxHeight}vh;
    `}

  ${({ $minHeight }) =>
    $minHeight &&
    css`
      min-height: ${$minHeight}vh;
    `}
    display: flex;


  ${({ theme }) => theme.mediaWidth.upToMedium`
      width: 65vw;
      margin: 0;
    `}

  ${({ theme, $isMobile }) => theme.mediaWidth.upToSmall`
      width: 85vw;

      ${$isMobile &&
        css`
          width: 100vw;
          border-radius: 20px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        `}
    `}

  ${({ theme, $isMobile }) => theme.mediaWidth.upToExtraSmall`
      width: 85vw;
      
      ${$isMobile &&
        css`
          width: 100vw;
          border-radius: 0;
        `}
    `}
`;

export const StyledApplet = styled(Applet)`
  width: 100%;
  padding: 29px 26px;
`;

interface ModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  minHeight?: number | false;
  maxHeight?: number;
  children?: React.ReactNode;
}

export default function Modal({
  isOpen,
  onDismiss,
  minHeight = false,
  maxHeight = 50,
  children,
}: ModalProps) {
  const fadeTransition = useTransition(isOpen, null, {
    config: { duration: 200 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const [{ y }, set] = useSpring(() => ({
    y: 0,
    config: { mass: 1, tension: 210, friction: 20 },
  }));

  const bind = useGesture({
    onDrag: (state) => {
      set({
        y: state.down ? state.movement[1] : 0,
      });
      if (
        state.movement[1] > 300 ||
        (state.velocity > 3 && state.direction[1] > 0)
      ) {
        onDismiss();
      }
    },
  });

  return (
    <>
      {fadeTransition.map(
        ({ item, key, props }) =>
          item && (
            <Dialog.Root
              key={key}
              onOpenChange={(open) => !open && onDismiss()}
              open
            >
              <Dialog.Portal>
                <StyledDialogOverlay style={props}>
                  <StyledDialogContent
                    {...(isMobile
                      ? {
                          ...bind(),
                          style: {
                            transform: y.interpolate(
                              (y) =>
                                `translateY(${(y as number) > 0 ? y : 0}px)`
                            ),
                          },
                        }
                      : {})}
                    aria-label="dialog content"
                    $minHeight={minHeight}
                    $maxHeight={maxHeight}
                    $isMobile={isMobile}
                  >
                    <StyledApplet>{children}</StyledApplet>
                  </StyledDialogContent>
                </StyledDialogOverlay>
              </Dialog.Portal>
            </Dialog.Root>
          )
      )}
    </>
  );
}
