import { Currency, Pair } from "@beamswap/sdk";
import { ButtonHTMLAttributes, FC } from "react";
import { styled } from "styled-components";
import DoubleCurrencyLogo from "../DoubleLogo";
import CurrencyLogo from "../CurrencyLogo";
import { ReactComponent as ChevronDownIcon } from "../../assets/svg/chevron_down.svg";
import { useTranslation } from "react-i18next";

export const CurrencySelect = styled.button`
  border-radius: 9999px;
  border: 1px solid #131313;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.11) 0%,
      rgba(255, 255, 255, 0.25) 4.48%,
      rgba(255, 255, 255, 0) 45.18%,
      rgba(255, 255, 255, 0) 78.33%
    ),
    #242424;
  background-blend-mode: overlay, normal;
  box-shadow: 0px -2px 2px 0px rgba(255, 255, 255, 0.07) inset,
    0px 2px 2px 0px rgba(0, 0, 0, 0.25);

  align-items: center;
  height: 2.2rem;
  font-size: 20px;
  font-weight: 400;

  color: #fff;
  outline: none;
  cursor: pointer;
  user-select: none;
  border: none;
  z-index: 1;
  white-space: nowrap;
`;

const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledTokenName = styled.span<{ $active?: boolean }>`
  ${({ $active }) =>
    $active
      ? "  margin: 0 0.25rem 0 0.75rem;"
      : "  margin: 0 0.25rem 0 0.25rem;"}
  font-size:  ${({ $active }) => ($active ? "20px" : "16px")};
`;

const StyledDropDown = styled(ChevronDownIcon)`
  margin: 0 0.25rem 0 0.5rem;
  height: 35%;
`;

interface CurrencySelectorProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  currency?: Currency | null;
  pair?: Pair | null;
  disableCurrencySelect?: boolean;
}

const CurrencySelector: FC<CurrencySelectorProps> = ({
  currency,
  pair,
  disableCurrencySelect,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <CurrencySelect className="open-currency-select-button" {...props}>
      <Aligner>
        {pair ? (
          <DoubleCurrencyLogo
            currency0={pair.token0}
            currency1={pair.token1}
            size={24}
            margin={true}
          />
        ) : currency ? (
          <CurrencyLogo currency={currency} size={"24px"} />
        ) : null}
        {pair ? (
          <StyledTokenName className="pair-name-container">
            {pair?.token0.symbol}:{pair?.token1.symbol}
          </StyledTokenName>
        ) : (
          <StyledTokenName
            className="token-symbol-container"
            $active={Boolean(currency && currency.symbol)}
          >
            {(currency && currency.symbol && currency.symbol.length > 20
              ? currency.symbol.slice(0, 4) +
                "..." +
                currency.symbol.slice(
                  currency.symbol.length - 5,
                  currency.symbol.length
                )
              : currency?.symbol) || <>&nbsp;{t("selectToken")}</>}
          </StyledTokenName>
        )}
        {!disableCurrencySelect && <StyledDropDown />}
      </Aligner>
    </CurrencySelect>
  );
};

export default CurrencySelector;
