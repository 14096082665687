import React from "react";

interface Props {
  className?: string;
}

export const BeamSwapLogoIcon = (props: Props) => {
  return (
    <svg
      width="29"
      height="25"
      viewBox="0 0 29 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 6.25H28.5709V12.5004H0V6.25Z"
        fill="url(#paint0_linear_511_348)"
      />
      <path
        d="M0 2C0 0.895429 0.895431 0 2 0H26.5706C27.6752 0 28.5706 0.895431 28.5706 2V6.25036H0V2Z"
        fill="url(#paint1_linear_511_348)"
      />
      <path
        d="M0 18.75H28.5709V23.0004C28.5709 24.1049 27.6755 25.0004 26.5709 25.0004H2C0.895431 25.0004 0 24.1049 0 23.0004V18.75Z"
        fill="url(#paint2_linear_511_348)"
      />
      <path
        d="M0 12.5024H28.5709V18.7528H0V12.5024Z"
        fill="url(#paint3_linear_511_348)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_511_348"
          x1="14.1998"
          y1="9.52904"
          x2="14.1998"
          y2="16.5006"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF6B6B" />
          <stop offset="0.33" stopColor="#FE1414" />
          <stop offset="0.6" stopColor="#8E0900" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_511_348"
          x1="15.6431"
          y1="3.27904"
          x2="15.6431"
          y2="10.2506"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBDBFF" />
          <stop offset="0.33" stopColor="#139EDD" />
          <stop offset="0.6" stopColor="#B9F7EA" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_511_348"
          x1="14.2861"
          y1="22.029"
          x2="14.2861"
          y2="29.0006"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBFFCA" />
          <stop offset="0.33" stopColor="#48DD13" />
          <stop offset="0.6" stopColor="#008805" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_511_348"
          x1="14.2861"
          y1="15.7815"
          x2="14.2861"
          y2="22.753"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F1E869" />
          <stop offset="0.33" stopColor="#FEA514" />
          <stop offset="0.6" stopColor="#FF4539" />
        </linearGradient>
      </defs>
    </svg>
  );
};
