import React from 'react'

interface Props {
  className?: string
}

export const BeamWordmarkIcon = (props: Props) => {
  return (
    <svg height="8" width="25" fill="none" viewBox="0 0 25 8" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 6.9843H1.10688V6.36563C1.40653 6.70707 1.83257 7.15044 2.80287 7.15044C4.64461 7.15044 5.30609 5.42387 5.30609 4.39853C5.30609 3.04092 4.30827 1.68331 2.77535 1.68331C2.21274 1.68331 1.62363 1.89531 1.20574 2.32032V0.150391H0V6.9843ZM1.1344 4.35266C1.1344 3.60455 1.70619 2.81057 2.63164 2.81057C3.39402 2.81057 4.10137 3.4109 4.10137 4.4077C4.10137 5.4045 3.43887 6.02419 2.64999 6.02419C1.94265 6.02419 1.13542 5.48808 1.13542 4.35266H1.1344Z"
        fill="#EFEFEF"
      />
      <path
        d="M10.6602 4.97162C10.6969 4.80548 10.7325 4.59348 10.7325 4.35295C10.7325 3.06057 9.77957 1.68359 8.12944 1.68359C6.47931 1.68359 5.48047 2.98617 5.48047 4.4355C5.48047 5.88484 6.60569 7.15072 8.13861 7.15072C9.32703 7.15072 10.279 6.29152 10.5695 5.3681H9.29951C9.07222 5.79311 8.67371 6.02346 8.13861 6.02346C7.27635 6.02346 6.80546 5.30287 6.75043 4.9706H10.6602V4.97162ZM6.71373 3.98399C6.94 3.03305 7.64836 2.81086 8.13759 2.81086C8.78174 2.81086 9.3627 3.16147 9.49826 3.98399H6.71373Z"
        fill="#EFEFEF"
      />
      <path
        d="M16.2001 1.8589H15.0932V2.50509H15.0748C15.0382 2.43171 14.4307 1.68359 13.4054 1.68359C12.008 1.68359 10.8838 2.81901 10.8838 4.38964C10.8838 6.09786 12.0355 7.15072 13.333 7.15072C13.968 7.15072 14.712 6.80928 15.0932 6.34757V6.98459H16.2001V1.8589ZM15.0575 4.40799C15.0575 5.35892 14.4225 6.02448 13.5878 6.02448C12.7531 6.02448 12.0906 5.31306 12.0906 4.4457C12.0906 3.67924 12.5981 2.81086 13.5878 2.81086C14.3043 2.81086 15.0575 3.34697 15.0575 4.40799Z"
        fill="#EFEFEF"
      />
      <path
        d="M16.7539 6.98552H17.9607V4.42727C17.9607 3.88198 17.9607 2.81077 18.9218 2.81077C19.6658 2.81077 19.9288 3.43862 19.9288 4.14086V6.98552H21.1356V4.42727C21.1356 4.10417 21.1264 3.5966 21.317 3.29185C21.4892 2.99627 21.8164 2.81179 22.1517 2.81179C23.1047 2.81179 23.1047 3.84631 23.1047 4.14188V6.98654H24.3115V3.99409C24.3115 3.23681 24.13 2.74758 23.9303 2.47952C23.7489 2.22064 23.2586 1.68555 22.27 1.68555C21.9163 1.68555 21.1723 1.72224 20.6463 2.4887C20.1744 1.74976 19.4671 1.68555 19.195 1.68555C18.3245 1.68555 17.979 2.12891 17.8791 2.29504H17.8608V1.86085H16.7539V6.98654V6.98552Z"
        fill="#EFEFEF"
      />
    </svg>
  )
}
