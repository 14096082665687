import { Field } from "../../state/swap/actions";
import { useUserSlippageTolerance } from "../../state/user/hooks";
import { TYPE } from "../../theme";
import {
  computeSlippageAdjustedAmounts,
  computeTradePriceBreakdown,
} from "../../utils/prices";
import { Lcd } from "../Applet";
import { AutoColumn } from "../Column";
import QuestionHelper from "../QuestionHelper";
import { RowBetween, RowFixed } from "../Row";
import { Seperator } from "../Seperator";
import FormattedPriceImpact from "./FormattedPriceImpact";
import SwapRoute from "./SwapRoute";
import { SectionBreak } from "./styleds";
import { Trade, TradeType } from "@beamswap/sdk";
import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";

const AnimationWrapper = styled.div<{ $show: boolean }>`
  max-height: ${({ $show }) => ($show ? "100%" : "0")};
  transition: max-height 300ms ease-in-out;
`;

export const StyledLcd = styled(Lcd)`
  padding: 10px;
`;

function TradeSummary({
  trade,
  allowedSlippage,
}: {
  trade: Trade;
  allowedSlippage: number;
}) {
  const theme = useContext(ThemeContext);
  const { priceImpactWithoutFee, realizedLPFee } = computeTradePriceBreakdown(
    trade
  );
  const isExactIn = trade.tradeType === TradeType.EXACT_INPUT;
  const slippageAdjustedAmounts = computeSlippageAdjustedAmounts(
    trade,
    allowedSlippage
  );

  return (
    <>
      <AutoColumn>
        <RowBetween>
          <RowFixed>
            <TYPE.Black fontSize={12} fontWeight={400} color={theme?.text6}>
              {isExactIn ? "Minimum received" : "Maximum sold"}
            </TYPE.Black>
            <QuestionHelper
              isSmall
              text="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed."
            />
          </RowFixed>
          <RowFixed>
            <TYPE.Black color={theme?.text1} fontSize={12}>
              {isExactIn
                ? `${slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4)} ${
                    trade.outputAmount.currency.symbol
                  }` ?? "-"
                : `${slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4)} ${
                    trade.inputAmount.currency.symbol
                  }` ?? "-"}
            </TYPE.Black>
          </RowFixed>
        </RowBetween>
        <RowBetween>
          <RowFixed>
            <TYPE.Black fontSize={12} fontWeight={400} color={theme?.text6}>
              Price Impact
            </TYPE.Black>
            <QuestionHelper
              isSmall
              text="The difference between the market price and estimated price due to trade size."
            />
          </RowFixed>
          <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
        </RowBetween>

        <RowBetween>
          <RowFixed>
            <TYPE.Black fontSize={12} fontWeight={400} color={theme?.text6}>
              Liquidity Provider Fee
            </TYPE.Black>
            <QuestionHelper
              isSmall
              text="A portion of each trade (0.30%) goes to liquidity providers as a protocol incentive."
            />
          </RowFixed>
          <TYPE.Black fontSize={12} color={theme?.text1}>
            {realizedLPFee
              ? `${realizedLPFee.toSignificant(4)} ${
                  trade.inputAmount.currency.symbol
                }`
              : "-"}
          </TYPE.Black>
        </RowBetween>
      </AutoColumn>
    </>
  );
}

export interface AdvancedSwapDetailsProps {
  trade?: Trade;
  children?: React.ReactNode;
}

export function AdvancedSwapDetails({
  trade,
  children,
}: AdvancedSwapDetailsProps) {
  const theme = useContext(ThemeContext);

  const [allowedSlippage] = useUserSlippageTolerance();

  const showRoute = Boolean(trade && trade.route.path.length > 2);

  return (
    <AutoColumn $gap="md">
      <StyledLcd>
        {children}

        <AnimationWrapper $show={Boolean(trade)}>
          {trade && (
            <>
              <Seperator />

              <TradeSummary trade={trade} allowedSlippage={allowedSlippage} />
              {showRoute && (
                <>
                  <SectionBreak />
                  <AutoColumn>
                    <RowFixed>
                      <TYPE.Black
                        fontSize={12}
                        fontWeight={400}
                        color={theme?.text6}
                      >
                        Route
                      </TYPE.Black>
                      <QuestionHelper
                        isSmall
                        text="Routing through these tokens resulted in the best price for your trade."
                      />
                    </RowFixed>
                    <SwapRoute trade={trade} />
                  </AutoColumn>
                </>
              )}
            </>
          )}
        </AnimationWrapper>
      </StyledLcd>
    </AutoColumn>
  );
}
