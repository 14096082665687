import React from "react";
import styled from "styled-components";
import GrainPng from "../../assets/images/grain.png";
import LcdTilePng from "../../assets/images/lcd-tile.png";

const Container = styled.div`
  position: relative;
  background-color: #222222;
  border-radius: 20px;
  overflow: hidden;
  padding-top: 40px;
  padding-left: 45px;
  padding-right: 45px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 60px;

  border: 1px solid #313131;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    border-radius: 0;
  `}
`;

const GrainOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-image: url(${GrainPng});
  background-repeat: repeat;
  width: 100%;
  height: 100%;
  opacity: 0.01;
  pointer-events: none;
`;

const LeftShadow = styled.div`
  position: absolute;
  top: 27px;
  bottom: 18px;
  left: 5px;

  width: 6px;

  z-index: 2;

  background: rgba(255, 255, 255, 0.1);
  filter: blur(10px);
`;

const TopShadow = styled.div`
  position: absolute;
  top: 7px;
  left: 12px;
  right: 12px;

  height: 6px;

  z-index: 2;

  background: linear-gradient(
    110.23deg,
    #d9d9d9 -219.47%,
    rgba(217, 217, 217, 0) 238.09%
  );
  opacity: 0.2;
  filter: blur(5px);
  border-radius: 20px;
`;

const LcdContainer = styled.div`
  overflow: hidden;
  box-sizing: border-box;
  position: relative;

  background-color: #151515;
  border: 1px solid #131313;
  border-radius: 10px;
  z-index: 1;
`;

const Background = styled.div`
  background-image: url(${LcdTilePng});
  background-repeat: repeat;
  background-size: 50%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  z-index: -1;
  pointer-events: none;
`;

const Applet = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <Container className={className}>
      <GrainOverlay />
      <TopShadow />
      <LeftShadow />
      <>{children}</>
    </Container>
  );
};

interface LcdProps {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const Lcd = ({ children, className, ...props }: LcdProps) => (
  <LcdContainer className={className} {...props}>
    <Background />
    {children}
  </LcdContainer>
);

export { Applet, Lcd };
