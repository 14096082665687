import { useActiveWeb3React } from "../../hooks";
import useENS from "../../hooks/useENS";
import { ExternalLink, TYPE } from "../../theme";
import { getEtherscanLink } from "../../utils";
import { Lcd } from "../Applet";
import { AutoColumn } from "../Column";
import { RowBetween } from "../Row";
import { useContext, useCallback, ChangeEventHandler } from "react";
import styled, { ThemeContext } from "styled-components";

const InputPanel = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  position: relative;
  border-radius: 1.25rem;
  background-color: ${({ theme }) => theme.bg1};
  z-index: 1;
  width: 100%;
`;

const ContainerRow = styled.div<{ $error: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.25rem;
  border: 1px solid ${({ $error, theme }) => ($error ? theme.red1 : theme.bg2)};
  transition: border-color 300ms
      ${({ $error }) => ($error ? "step-end" : "step-start")},
    color 500ms ${({ $error }) => ($error ? "step-end" : "step-start")};
  background-color: ${({ theme }) => theme.bg1};
`;

const InputContainer = styled.div`
  flex: 1;
  padding: 1rem;
`;

const Input = styled.input<{ $error?: boolean }>`
  font-size: 12px;
  outline: none;
  border: none;
  flex: 1 1 auto;
  width: 0;

  transition: color 300ms
    ${({ $error }) => ($error ? "step-end" : "step-start")};

  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  background-color: transparent;
  padding: 6px 10px;
  color: #fff;
  width: 100%;
  ::placeholder {
    color: ${({ theme }) => theme.text4};
  }

  -webkit-appearance: textfield;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.text4};
  }
`;

export default function AddressInputPanel({
  id,
  value,
  onChange,
}: {
  id?: string;
  // the typed string value
  value: string;
  // triggers whenever the typed value changes
  onChange: (value: string) => void;
}) {
  const { chainId } = useActiveWeb3React();
  const theme = useContext(ThemeContext);

  const { address, loading, name } = useENS(value);

  const handleInput = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const input = event.target.value;
      const withoutSpaces = input.replace(/\s+/g, "");
      onChange(withoutSpaces);
    },
    [onChange]
  );

  const error = Boolean(value.length > 0 && !loading && !address);

  return (
    <InputPanel id={id}>
      <ContainerRow $error={error}>
        <InputContainer>
          <AutoColumn $gap="sm">
            <RowBetween>
              <TYPE.Black color={theme?.text2} fontWeight={400} fontSize={12}>
                Recipient
              </TYPE.Black>
              {address && chainId && (
                <ExternalLink
                  href={getEtherscanLink(chainId, name ?? address, "address")}
                  style={{ fontSize: "14px" }}
                >
                  (View on block explorer)
                </ExternalLink>
              )}
            </RowBetween>
            <Lcd>
              <Input
                className="recipient-address-input"
                type="text"
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                placeholder="Wallet Address or ENS name"
                $error={error}
                pattern="^(0x[a-fA-F0-9]{40})$"
                onChange={handleInput}
                value={value}
              />
            </Lcd>
          </AutoColumn>
        </InputContainer>
      </ContainerRow>
    </InputPanel>
  );
}
