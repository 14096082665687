import { AutoColumn } from "../Column";
import { transparentize } from "polished";
import React from "react";
import { AlertTriangle } from "react-feather";
import { Text } from "rebass";
import styled, { css } from "styled-components";
import { Pill } from "../Pill";

export const Wrapper = styled.div`
  position: relative;
`;

export const ArrowWrapper = styled(Pill)<{ $clickable: boolean }>`
  padding: 2px;
  width: 38px;
  height: 38px;

  border-radius: 38px;
  border: 1px solid #000;
  background: radial-gradient(
      111.24% 111.24% at 50.36% -11.23%,
      rgba(255, 255, 255, 0.29) 0%,
      rgba(255, 255, 255, 0.65) 4.48%,
      rgba(255, 255, 255, 0) 45.18%,
      rgba(255, 255, 255, 0) 78.33%
    ),
    linear-gradient(0deg, #222 0%, #222 100%), #d9d9d9;
  background-blend-mode: overlay, normal, normal;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px -2px 2px 0px rgba(255, 255, 255, 0.07) inset;

  ${({ $clickable }) =>
    $clickable
      ? css`
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }
        `
      : null}
`;

export const SectionBreak = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.bg3};
`;

export const BottomGrouping = styled.div`
  margin-top: 1rem;
`;

export const ErrorText = styled(Text)<{ severity?: 0 | 1 | 2 | 3 | 4 }>`
  color: ${({ theme, severity }) =>
    severity === 3 || severity === 4
      ? theme.red1
      : severity === 2
      ? theme.yellow2
      : severity === 1
      ? theme.text1
      : theme.green1};
`;

export const StyledBalanceMaxMini = styled.button`
  height: 22px;
  width: 22px;
  background-color: ${({ theme }) => theme.bg2};
  border: none;
  border-radius: 50%;
  padding: 0.2rem;
  font-size: 0.875rem;
  font-weight: 400;
  margin-left: 0.4rem;
  cursor: pointer;
  color: ${({ theme }) => theme.text2};
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;

  :hover {
    background-color: ${({ theme }) => theme.bg3};
  }
  :focus {
    background-color: ${({ theme }) => theme.bg3};
    outline: none;
  }
`;

export const TruncatedText = styled(Text)`
  text-overflow: ellipsis;
  width: 220px;
  overflow: hidden;
`;

// styles
export const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: ".";
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: ".";
    }
    33% {
      content: "..";
    }
    66% {
      content: "...";
    }
  }
`;

const SwapCallbackErrorInner = styled.div`
  background-color: ${({ theme }) => transparentize(0.9, theme.red1)};
  border-radius: 1rem;
  display: flex;
  align-items: center;
  font-size: 0.825rem;
  width: 100%;
  padding: 3rem 1.25rem 1rem 1rem;
  margin-top: -2rem;
  color: ${({ theme }) => theme.red1};
  z-index: -1;
  p {
    padding: 0;
    margin: 0;
    font-weight: 400;
  }
`;

const SwapCallbackErrorInnerAlertTriangle = styled.div`
  background-color: ${({ theme }) => transparentize(0.9, theme.red1)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  border-radius: 12px;
  min-width: 48px;
  height: 48px;
`;

export function SwapCallbackError({ error }: { error: string }) {
  return (
    <SwapCallbackErrorInner>
      <SwapCallbackErrorInnerAlertTriangle>
        <AlertTriangle size={24} />
      </SwapCallbackErrorInnerAlertTriangle>
      <p>{error}</p>
    </SwapCallbackErrorInner>
  );
}

export const SwapShowAcceptChanges = styled(AutoColumn)`
  background-color: ${({ theme }) => transparentize(0.9, theme.primary1)};
  color: ${({ theme }) => theme.primary1};
  padding: 0.5rem;
  border-radius: 12px;
  margin-top: 8px;
`;
