import { useAccount } from "wagmi";
import {
  ButtonPrimary,
  ButtonError,
  ButtonConfirmed,
} from "../../components/Button";
import { LightCard } from "../../components/Card";
import { AutoColumn, ColumnCenter } from "../../components/Column";
import { ConnectButton } from "../../components/ConnectButton";
import CurrencyInputPanel from "../../components/CurrencyInputPanel";
import CurrencyLogo from "../../components/CurrencyLogo";
import DoubleCurrencyLogo from "../../components/DoubleLogo";
import { AddRemoveTabs } from "../../components/NavigationTabs";
import { MinimalPositionCard } from "../../components/PositionCard";
import Row, { RowBetween, RowFixed } from "../../components/Row";
import Slider from "../../components/Slider";
import TransactionConfirmationModal, {
  ConfirmationModalContent,
} from "../../components/TransactionConfirmationModal";
import { Dots } from "../../components/swap/styleds";
import { ROUTER_ADDRESS } from "../../constants";
import { useActiveWeb3React } from "../../hooks";
import { useCurrency } from "../../hooks/Tokens";
import {
  useApproveCallback,
  ApprovalState,
} from "../../hooks/useApproveCallback";
import { usePairContract } from "../../hooks/useContract";
import { Field } from "../../state/burn/actions";
import { useBurnActionHandlers } from "../../state/burn/hooks";
import { useDerivedBurnInfo, useBurnState } from "../../state/burn/hooks";
import { useTransactionAdder } from "../../state/transactions/hooks";
import {
  useUserDeadline,
  useUserSlippageTolerance,
} from "../../state/user/hooks";
import { StyledInternalLink, TYPE } from "../../theme";
import { calculateSlippageAmount, getRouterContract } from "../../utils";
//calculateGasMargin,
import { currencyId } from "../../utils/currencyId";
import useDebouncedChangeHandler from "../../utils/useDebouncedChangeHandler";
import { wrappedCurrency } from "../../utils/wrappedCurrency";
import AppBody from "../AppBody";
import { ClickableText, MaxButton, Wrapper } from "../Pool/styleds";
import { Currency, currencyEquals, DEV, Percent, WDEV } from "@beamswap/sdk";
import { BigNumber } from "@ethersproject/bignumber";
import { splitSignature } from "@ethersproject/bytes";
import { Contract } from "@ethersproject/contracts";
import { TransactionResponse } from "@ethersproject/providers";
import { useCallback, useContext, useMemo, useState } from "react";
import { ArrowDown, Plus } from "react-feather";
import { RouteComponentProps } from "react-router";
import { Text } from "rebass";
import { ThemeContext } from "styled-components";
import useConnectedChain from "../../hooks/useConnectedChain";

export default function RemoveLiquidity({
  history,
  match: {
    params: { currencyIdA, currencyIdB },
  },
}: RouteComponentProps<{ currencyIdA: string; currencyIdB: string }>) {
  const [currencyA, currencyB] = [
    useCurrency(currencyIdA) ?? undefined,
    useCurrency(currencyIdB) ?? undefined,
  ];
  const { library } = useActiveWeb3React();
  const { chainId, isSupported } = useConnectedChain();
  const { address } = useAccount();
  const [tokenA, tokenB] = useMemo(
    () => [
      wrappedCurrency(currencyA, chainId),
      wrappedCurrency(currencyB, chainId),
    ],
    [currencyA, currencyB, chainId]
  );

  const theme = useContext(ThemeContext);

  // burn state
  const { independentField, typedValue } = useBurnState();
  const { pair, parsedAmounts, error } = useDerivedBurnInfo(
    currencyA ?? undefined,
    currencyB ?? undefined
  );
  const { onUserInput: _onUserInput } = useBurnActionHandlers();
  const isValid = !error;

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [showDetailed, setShowDetailed] = useState<boolean>(false);
  const [attemptingTxn, setAttemptingTxn] = useState(false); // clicked confirm

  // txn values
  const [txHash, setTxHash] = useState<string>("");
  const [deadline] = useUserDeadline();
  const [allowedSlippage] = useUserSlippageTolerance();

  const formattedAmounts = {
    [Field.LIQUIDITY_PERCENT]: parsedAmounts[Field.LIQUIDITY_PERCENT].equalTo(
      "0"
    )
      ? "0"
      : parsedAmounts[Field.LIQUIDITY_PERCENT].lessThan(new Percent("1", "100"))
      ? "<1"
      : parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0),
    [Field.LIQUIDITY]:
      independentField === Field.LIQUIDITY
        ? typedValue
        : parsedAmounts[Field.LIQUIDITY]?.toSignificant(6) ?? "",
    [Field.CURRENCY_A]:
      independentField === Field.CURRENCY_A
        ? typedValue
        : parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) ?? "",
    [Field.CURRENCY_B]:
      independentField === Field.CURRENCY_B
        ? typedValue
        : parsedAmounts[Field.CURRENCY_B]?.toSignificant(6) ?? "",
  };

  // pair contract
  const pairContract: Contract | null = usePairContract(
    pair?.liquidityToken?.address
  );

  // allowance handling
  const [signatureData, setSignatureData] = useState<{
    v: number;
    r: string;
    s: string;
    deadline: number;
  } | null>(null);
  const [approval, approveCallback] = useApproveCallback(
    parsedAmounts[Field.LIQUIDITY],
    ROUTER_ADDRESS[chainId ? chainId : ""]
  );
  async function onAttemptToApprove() {
    if (!pairContract || !pair || !library)
      throw new Error("missing dependencies");
    const liquidityAmount = parsedAmounts[Field.LIQUIDITY];
    if (!liquidityAmount) throw new Error("missing liquidity amount");
    // try to gather a signature for permission
    const nonce = await pairContract.nonces(address);

    const deadlineForSignature: number =
      Math.ceil(Date.now() / 1000) + deadline;

    const EIP712Domain = [
      { name: "name", type: "string" },
      { name: "version", type: "string" },
      { name: "chainId", type: "uint256" },
      { name: "verifyingContract", type: "address" },
    ];
    const domain = {
      name: "Uniswap V2", //correct domain name!!!!!!!!!!!!!!!!!!!!!!!
      version: "1",
      chainId: chainId,
      verifyingContract: pair.liquidityToken.address,
    };
    const Permit = [
      { name: "owner", type: "address" },
      { name: "spender", type: "address" },
      { name: "value", type: "uint256" },
      { name: "nonce", type: "uint256" },
      { name: "deadline", type: "uint256" },
    ];
    const message = {
      owner: address,
      spender: ROUTER_ADDRESS,
      value: liquidityAmount.raw.toString(),
      nonce: nonce.toHexString(),
      deadline: deadlineForSignature,
    };
    const data = JSON.stringify({
      types: {
        EIP712Domain,
        Permit,
      },
      domain,
      primaryType: "Permit",
      message,
    });

    library
      .send("eth_signTypedData_v4", [address, data])
      .then(splitSignature)
      .then((signature) => {
        setSignatureData({
          v: signature.v,
          r: signature.r,
          s: signature.s,
          deadline: deadlineForSignature,
        });
      })
      .catch((error) => {
        // for all errors other than 4001 (EIP-1193 user rejected request), fall back to manual approve
        if (error?.code !== 4001) {
          approveCallback();
        }
      });
  }

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      setSignatureData(null);
      return _onUserInput(field, typedValue);
    },
    [_onUserInput]
  );

  const onLiquidityInput = useCallback(
    (typedValue: string): void => onUserInput(Field.LIQUIDITY, typedValue),
    [onUserInput]
  );
  const onCurrencyAInput = useCallback(
    (typedValue: string): void => onUserInput(Field.CURRENCY_A, typedValue),
    [onUserInput]
  );
  const onCurrencyBInput = useCallback(
    (typedValue: string): void => onUserInput(Field.CURRENCY_B, typedValue),
    [onUserInput]
  );

  // tx sending
  const addTransaction = useTransactionAdder();
  async function onRemove() {
    if (!chainId || !library || !address)
      throw new Error("missing dependencies");
    const {
      [Field.CURRENCY_A]: currencyAmountA,
      [Field.CURRENCY_B]: currencyAmountB,
    } = parsedAmounts;
    if (!currencyAmountA || !currencyAmountB) {
      throw new Error("missing currency amounts");
    }
    const router = getRouterContract(chainId, library, address);

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(
        currencyAmountA,
        allowedSlippage
      )[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(
        currencyAmountB,
        allowedSlippage
      )[0],
    };

    if (!currencyA || !currencyB) throw new Error("missing tokens");
    const liquidityAmount = parsedAmounts[Field.LIQUIDITY];
    if (!liquidityAmount) throw new Error("missing liquidity amount");

    const currencyBIsETH = currencyB === DEV;
    const oneCurrencyIsETH = currencyA === DEV || currencyBIsETH;
    const deadlineFromNow = Math.ceil(Date.now() / 1000) + deadline;

    if (!tokenA || !tokenB) throw new Error("could not wrap");

    let methodNames: string[],
      args: Array<string | string[] | number | boolean>;
    // we have approval, use normal remove liquidity
    if (approval === ApprovalState.APPROVED) {
      // removeLiquidityETH
      if (oneCurrencyIsETH) {
        methodNames = [
          "removeLiquidityETH",
          "removeLiquidityETHSupportingFeeOnTransferTokens",
        ];
        args = [
          currencyBIsETH ? tokenA.address : tokenB.address,
          liquidityAmount.raw.toString(),
          amountsMin[
            currencyBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B
          ].toString(),
          amountsMin[
            currencyBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A
          ].toString(),
          address,
          deadlineFromNow,
        ];
      }
      // removeLiquidity
      else {
        methodNames = ["removeLiquidity"];
        args = [
          tokenA.address,
          tokenB.address,
          liquidityAmount.raw.toString(),
          amountsMin[Field.CURRENCY_A].toString(),
          amountsMin[Field.CURRENCY_B].toString(),
          address,
          deadlineFromNow,
        ];
      }
    }
    // we have a signataure, use permit versions of remove liquidity
    else if (signatureData !== null) {
      // removeLiquidityETHWithPermit
      if (oneCurrencyIsETH) {
        methodNames = [
          "removeLiquidityETHWithPermit",
          "removeLiquidityETHWithPermitSupportingFeeOnTransferTokens",
        ];
        args = [
          currencyBIsETH ? tokenA.address : tokenB.address,
          liquidityAmount.raw.toString(),
          amountsMin[
            currencyBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B
          ].toString(),
          amountsMin[
            currencyBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A
          ].toString(),
          address,
          signatureData.deadline,
          false,
          signatureData.v,
          signatureData.r,
          signatureData.s,
        ];
      }
      // removeLiquidityETHWithPermit
      else {
        methodNames = ["removeLiquidityWithPermit"];
        args = [
          tokenA.address,
          tokenB.address,
          liquidityAmount.raw.toString(),
          amountsMin[Field.CURRENCY_A].toString(),
          amountsMin[Field.CURRENCY_B].toString(),
          address,
          signatureData.deadline,
          false,
          signatureData.v,
          signatureData.r,
          signatureData.s,
        ];
      }
    } else {
      throw new Error(
        "Attempting to confirm without approval or a signature. Please contact support."
      );
    }
    /*
    const safeGasEstimates: (BigNumber | undefined)[] = await Promise.all(
      methodNames.map(methodName =>
        router.estimateGas[methodName](...args)
          .then(calculateGasMargin)
          .catch(error => {
            console.error(`estimateGas failed`, methodName, args, error)
            return undefined
          })
      )
    )
    */
    const safeGasEstimates: BigNumber[] = [BigNumber.from("5000000")];
    const indexOfSuccessfulEstimation = safeGasEstimates.findIndex(
      (safeGasEstimate) => BigNumber.isBigNumber(safeGasEstimate)
    );

    // all estimations failed...
    if (indexOfSuccessfulEstimation === -1) {
      console.error("This transaction would fail. Please contact support.");
    } else {
      const methodName = methodNames[indexOfSuccessfulEstimation];
      const safeGasEstimate = safeGasEstimates[indexOfSuccessfulEstimation];

      setAttemptingTxn(true);
      await router[methodName](...args, {
        gasLimit: safeGasEstimate,
      })
        .then((response: TransactionResponse) => {
          setAttemptingTxn(false);

          addTransaction(response, {
            summary:
              "Remove " +
              parsedAmounts[Field.CURRENCY_A]?.toSignificant(3) +
              " " +
              currencyA?.symbol +
              " and " +
              parsedAmounts[Field.CURRENCY_B]?.toSignificant(3) +
              " " +
              currencyB?.symbol,
          });

          setTxHash(response.hash);
        })
        .catch((error: Error) => {
          setAttemptingTxn(false);
          // we only care if the error is something _other_ than the user rejected the tx
          console.error(error);
        });
    }
  }

  function modalHeader() {
    return (
      <AutoColumn $gap={"md"} style={{ marginTop: "20px" }}>
        <RowBetween $align="flex-end">
          <Text fontSize={24} fontWeight={400}>
            {parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)}
          </Text>
          <RowFixed gap="4px">
            <CurrencyLogo currency={currencyA} size={"24px"} />
            <Text fontSize={24} fontWeight={400} style={{ marginLeft: "10px" }}>
              {currencyA?.symbol}
            </Text>
          </RowFixed>
        </RowBetween>
        <RowFixed>
          <Plus size="16" color={theme?.text2} />
        </RowFixed>
        <RowBetween $align="flex-end">
          <Text fontSize={24} fontWeight={400}>
            {parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)}
          </Text>
          <RowFixed gap="4px">
            <CurrencyLogo currency={currencyB} size={"24px"} />
            <Text fontSize={24} fontWeight={400} style={{ marginLeft: "10px" }}>
              {currencyB?.symbol}
            </Text>
          </RowFixed>
        </RowBetween>

        <TYPE.Italic
          fontSize={12}
          color={theme?.text2}
          textAlign="left"
          padding={"12px 0 0 0"}
        >
          {`Output is estimated. If the price changes by more than ${allowedSlippage /
            100}% your transaction will revert.`}
        </TYPE.Italic>
      </AutoColumn>
    );
  }

  function modalBottom() {
    return (
      <>
        <RowBetween>
          <Text color={theme?.text2} fontWeight={400} fontSize={16}>
            {"UNI " + currencyA?.symbol + "/" + currencyB?.symbol} Burned
          </Text>
          <RowFixed>
            <DoubleCurrencyLogo
              currency0={currencyA}
              currency1={currencyB}
              margin={true}
            />
            <Text fontWeight={400} fontSize={16}>
              {parsedAmounts[Field.LIQUIDITY]?.toSignificant(6)}
            </Text>
          </RowFixed>
        </RowBetween>
        {pair && (
          <>
            <RowBetween>
              <Text color={theme?.text2} fontWeight={400} fontSize={16}>
                Price
              </Text>
              <Text fontWeight={400} fontSize={16} color={theme?.text1}>
                1 {currencyA?.symbol} ={" "}
                {tokenA ? pair.priceOf(tokenA).toSignificant(6) : "-"}{" "}
                {currencyB?.symbol}
              </Text>
            </RowBetween>
            <RowBetween>
              <div />
              <Text fontWeight={400} fontSize={16} color={theme?.text1}>
                1 {currencyB?.symbol} ={" "}
                {tokenB ? pair.priceOf(tokenB).toSignificant(6) : "-"}{" "}
                {currencyA?.symbol}
              </Text>
            </RowBetween>
          </>
        )}
        <ButtonPrimary
          disabled={
            !(approval === ApprovalState.APPROVED || signatureData !== null)
          }
          onClick={onRemove}
        >
          <Text fontWeight={400} fontSize={20}>
            Confirm
          </Text>
        </ButtonPrimary>
      </>
    );
  }

  const pendingText = `Removing ${parsedAmounts[
    Field.CURRENCY_A
  ]?.toSignificant(6)} ${currencyA?.symbol} and ${parsedAmounts[
    Field.CURRENCY_B
  ]?.toSignificant(6)} ${currencyB?.symbol}`;

  const liquidityPercentChangeCallback = useCallback(
    (value: number) => {
      onUserInput(Field.LIQUIDITY_PERCENT, value.toString());
    },
    [onUserInput]
  );

  const oneCurrencyIsETH = currencyA === DEV || currencyB === DEV;
  const oneCurrencyIsWDEV = Boolean(
    chainId &&
      ((currencyA && currencyEquals(WDEV[chainId], currencyA)) ||
        (currencyB && currencyEquals(WDEV[chainId], currencyB)))
  );

  const handleSelectCurrencyA = useCallback(
    (currency: Currency) => {
      if (currencyIdB && currencyId(currency) === currencyIdB) {
        history.push(`/remove/${currencyId(currency)}/${currencyIdA}`);
      } else {
        history.push(`/remove/${currencyId(currency)}/${currencyIdB}`);
      }
    },
    [currencyIdA, currencyIdB, history]
  );
  const handleSelectCurrencyB = useCallback(
    (currency: Currency) => {
      if (currencyIdA && currencyId(currency) === currencyIdA) {
        history.push(`/remove/${currencyIdB}/${currencyId(currency)}`);
      } else {
        history.push(`/remove/${currencyIdA}/${currencyId(currency)}`);
      }
    },
    [currencyIdA, currencyIdB, history]
  );

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false);
    setSignatureData(null); // important that we clear signature data to avoid bad sigs
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.LIQUIDITY_PERCENT, "0");
    }
    setTxHash("");
  }, [onUserInput, txHash]);

  const [
    innerLiquidityPercentage,
    setInnerLiquidityPercentage,
  ] = useDebouncedChangeHandler(
    Number.parseInt(parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0)),
    liquidityPercentChangeCallback
  );

  return (
    <>
      <AppBody>
        <AddRemoveTabs adding={false} />
        <Wrapper>
          <TransactionConfirmationModal
            isOpen={showConfirm}
            onDismiss={handleDismissConfirmation}
            attemptingTxn={attemptingTxn}
            hash={txHash ? txHash : ""}
            content={() => (
              <ConfirmationModalContent
                title={"You will receive"}
                onDismiss={handleDismissConfirmation}
                topContent={modalHeader}
                bottomContent={modalBottom}
              />
            )}
            pendingText={pendingText}
          />
          <AutoColumn $gap="md">
            <LightCard>
              <AutoColumn $gap="20px">
                <RowBetween>
                  <Text fontWeight={400}>Amount</Text>
                  <ClickableText
                    fontWeight={400}
                    onClick={() => {
                      setShowDetailed(!showDetailed);
                    }}
                  >
                    {showDetailed ? "Simple" : "Detailed"}
                  </ClickableText>
                </RowBetween>
                <Row style={{ alignItems: "flex-end" }}>
                  <Text fontSize={72} fontWeight={400}>
                    {formattedAmounts[Field.LIQUIDITY_PERCENT]}%
                  </Text>
                </Row>
                {!showDetailed && (
                  <>
                    <Slider
                      value={innerLiquidityPercentage}
                      onChange={setInnerLiquidityPercentage}
                    />
                    <RowBetween>
                      <MaxButton
                        onClick={() =>
                          onUserInput(Field.LIQUIDITY_PERCENT, "25")
                        }
                        $width="20%"
                      >
                        25%
                      </MaxButton>
                      <MaxButton
                        onClick={() =>
                          onUserInput(Field.LIQUIDITY_PERCENT, "50")
                        }
                        $width="20%"
                      >
                        50%
                      </MaxButton>
                      <MaxButton
                        onClick={() =>
                          onUserInput(Field.LIQUIDITY_PERCENT, "75")
                        }
                        $width="20%"
                      >
                        75%
                      </MaxButton>
                      <MaxButton
                        onClick={() =>
                          onUserInput(Field.LIQUIDITY_PERCENT, "100")
                        }
                        $width="20%"
                      >
                        Max
                      </MaxButton>
                    </RowBetween>
                  </>
                )}
              </AutoColumn>
            </LightCard>
            {!showDetailed && (
              <>
                <ColumnCenter>
                  <ArrowDown size="16" color={theme?.text2} />
                </ColumnCenter>
                <LightCard>
                  <AutoColumn $gap="10px">
                    <RowBetween>
                      <Text fontSize={24} fontWeight={400}>
                        {formattedAmounts[Field.CURRENCY_A] || "-"}
                      </Text>
                      <RowFixed>
                        <CurrencyLogo
                          currency={currencyA}
                          style={{ marginRight: "12px" }}
                        />
                        <Text
                          fontSize={24}
                          fontWeight={400}
                          id="remove-liquidity-tokena-symbol"
                        >
                          {currencyA?.symbol}
                        </Text>
                      </RowFixed>
                    </RowBetween>
                    <RowBetween>
                      <Text fontSize={24} fontWeight={400}>
                        {formattedAmounts[Field.CURRENCY_B] || "-"}
                      </Text>
                      <RowFixed>
                        <CurrencyLogo
                          currency={currencyB}
                          style={{ marginRight: "12px" }}
                        />
                        <Text
                          fontSize={24}
                          fontWeight={400}
                          id="remove-liquidity-tokenb-symbol"
                        >
                          {currencyB?.symbol}
                        </Text>
                      </RowFixed>
                    </RowBetween>
                    {chainId && (oneCurrencyIsWDEV || oneCurrencyIsETH) ? (
                      <RowBetween style={{ justifyContent: "flex-end" }}>
                        {oneCurrencyIsETH ? (
                          <StyledInternalLink
                            to={`/remove/${
                              currencyA === DEV
                                ? WDEV[chainId].address
                                : currencyIdA
                            }/${
                              currencyB === DEV
                                ? WDEV[chainId].address
                                : currencyIdB
                            }`}
                          >
                            Receive WBEAM
                          </StyledInternalLink>
                        ) : oneCurrencyIsWDEV ? (
                          <StyledInternalLink
                            to={`/remove/${
                              currencyA &&
                              currencyEquals(currencyA, WDEV[chainId])
                                ? "ETH"
                                : currencyIdA
                            }/${
                              currencyB &&
                              currencyEquals(currencyB, WDEV[chainId])
                                ? "ETH"
                                : currencyIdB
                            }`}
                          >
                            Receive BEAM
                          </StyledInternalLink>
                        ) : null}
                      </RowBetween>
                    ) : null}
                  </AutoColumn>
                </LightCard>
              </>
            )}

            {showDetailed && (
              <>
                <CurrencyInputPanel
                  value={formattedAmounts[Field.LIQUIDITY]}
                  onUserInput={onLiquidityInput}
                  onMax={() => {
                    onUserInput(Field.LIQUIDITY_PERCENT, "100");
                  }}
                  disableCurrencySelect
                  currency={pair?.liquidityToken}
                  pair={pair}
                  id="liquidity-amount"
                />
                <ColumnCenter>
                  <ArrowDown size="16" color={theme?.text2} />
                </ColumnCenter>
                <CurrencyInputPanel
                  hideBalance={true}
                  value={formattedAmounts[Field.CURRENCY_A]}
                  onUserInput={onCurrencyAInput}
                  onMax={() => onUserInput(Field.LIQUIDITY_PERCENT, "100")}
                  currency={currencyA}
                  label={"Output"}
                  onCurrencySelect={handleSelectCurrencyA}
                  id="remove-liquidity-tokena"
                />
                <ColumnCenter>
                  <Plus size="16" color={theme?.text2} />
                </ColumnCenter>
                <CurrencyInputPanel
                  hideBalance={true}
                  value={formattedAmounts[Field.CURRENCY_B]}
                  onUserInput={onCurrencyBInput}
                  onMax={() => onUserInput(Field.LIQUIDITY_PERCENT, "100")}
                  currency={currencyB}
                  label={"Output"}
                  onCurrencySelect={handleSelectCurrencyB}
                  id="remove-liquidity-tokenb"
                />
              </>
            )}
            {pair && (
              <div style={{ padding: "10px 20px" }}>
                <RowBetween>
                  Price:
                  <div>
                    1 {currencyA?.symbol} ={" "}
                    {tokenA ? pair.priceOf(tokenA).toSignificant(6) : "-"}{" "}
                    {currencyB?.symbol}
                  </div>
                </RowBetween>
                <RowBetween>
                  <div />
                  <div>
                    1 {currencyB?.symbol} ={" "}
                    {tokenB ? pair.priceOf(tokenB).toSignificant(6) : "-"}{" "}
                    {currencyA?.symbol}
                  </div>
                </RowBetween>
              </div>
            )}
            <div style={{ position: "relative" }}>
              {!address || !isSupported ? (
                <ConnectButton buttonComponent={ButtonPrimary} />
              ) : (
                <RowBetween>
                  <ButtonConfirmed
                    onClick={onAttemptToApprove}
                    confirmed={
                      approval === ApprovalState.APPROVED ||
                      signatureData !== null
                    }
                    disabled={
                      approval !== ApprovalState.NOT_APPROVED ||
                      signatureData !== null
                    }
                    mr="0.5rem"
                    fontWeight={400}
                    fontSize={16}
                  >
                    {approval === ApprovalState.PENDING ? (
                      <Dots>Approving</Dots>
                    ) : approval === ApprovalState.APPROVED ||
                      signatureData !== null ? (
                      "Approved"
                    ) : (
                      "Approve"
                    )}
                  </ButtonConfirmed>
                  <ButtonError
                    onClick={() => {
                      setShowConfirm(true);
                    }}
                    disabled={
                      !isValid ||
                      (signatureData === null &&
                        approval !== ApprovalState.APPROVED)
                    }
                    error={
                      !isValid &&
                      !!parsedAmounts[Field.CURRENCY_A] &&
                      !!parsedAmounts[Field.CURRENCY_B]
                    }
                  >
                    <Text fontSize={16} fontWeight={400}>
                      {error || "Remove"}
                    </Text>
                  </ButtonError>
                </RowBetween>
              )}
            </div>
          </AutoColumn>
        </Wrapper>
      </AppBody>

      {pair ? (
        <AutoColumn style={{ minWidth: "20rem", marginTop: "1rem" }}>
          <MinimalPositionCard showUnwrapped={oneCurrencyIsWDEV} pair={pair} />
        </AutoColumn>
      ) : null}
    </>
  );
}
