// @ts-expect-error - Loader not setup, but it works
import SuisseRegularTtf from "../assets/fonts/SuisseIntl-Regular.ttf";
import { Colors } from "./styled";
import React from "react";
import { Text, TextProps } from "rebass";
import styled, {
  ThemeProvider as StyledComponentsThemeProvider,
  createGlobalStyle,
  css,
  DefaultTheme,
} from "styled-components";

export * from "./components";

const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 950,
  upToMedium: 960,
  upToLarge: 1280,
};

const mediaWidthTemplates: {
  [width in keyof typeof MEDIA_WIDTHS]: typeof css;
} = Object.keys(MEDIA_WIDTHS).reduce((accumulator, size) => {
  (accumulator as any)[size] = (a: any, b: any, c: any) => css`
    @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
      ${css(a, b, c)}
    }
  `;
  return accumulator;
}, {}) as any;

const white = "#FFFFFF";
const black = "#000000";

export function colors(): Colors {
  return {
    // base
    white,
    black,

    // text
    text1: "#FFFFFF",
    text2: "#C3C5CB",
    text3: "#f1f1f1",
    text4: "#565A69",
    text5: "#2C2F36",
    text6: "#878787",

    // backgrounds / greys
    bg1: "#2b2b2b",
    bg2: "#2C2F36",
    bg3: "#40444F",
    bg4: "#565A69",
    bg5: "#6C7284",

    //specialty colors
    modalBG: "rgba(0,0,0,.425)",
    advancedBG: "rgba(0,0,0,0.1)",

    //primary colors
    primary1: "#ebbcff",
    primary2: "#d6a2f7",
    primary3: "#c288f2",
    primary4: "#9f63db70", // added 70% opacity
    primary5: "#7c3fbf70", // added 70% opacity

    // color text
    primaryText1: "#ba80ff",

    // secondary colors
    secondary1: "#bf60e0",
    secondary2: "#90009f26", // added 26% opacity
    secondary3: "#65007326", // added 26% opacity

    // other
    red1: "#FF6871",
    red2: "#F82D3A",
    green1: "#27AE60",
    yellow1: "#FFE270",
    yellow2: "#F3841E",

    // dont wanna forget these blue yet
    // blue4: darkMode ? '#153d6f70' : '#C4D9F8',
    // blue5: darkMode ? '#153d6f70' : '#EBF4FF',
  };
}

export function theme(): DefaultTheme {
  return {
    ...colors(),

    grids: {
      sm: 8,
      md: 12,
      lg: 24,
    },

    //shadows
    shadow1: "#000",

    // media queries
    mediaWidth: mediaWidthTemplates,

    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `,
  };
}

export default function ThemeProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  //const darkMode = useIsDarkMode()

  const themeObject = theme();

  return (
    <StyledComponentsThemeProvider theme={themeObject}>
      {children}
    </StyledComponentsThemeProvider>
  );
}

const TextWrapper = styled(Text)<{ $color: keyof Colors }>`
  color: ${({ $color, theme }) => (theme as any)[$color]};
`;

export const TYPE = {
  Main(props: TextProps) {
    return <TextWrapper fontWeight={400} $color={"text2"} {...props} />;
  },
  Link(props: TextProps) {
    return <TextWrapper fontWeight={400} $color={"primary1"} {...props} />;
  },
  Black(props: TextProps) {
    return <TextWrapper fontWeight={400} $color={"text1"} {...props} />;
  },
  Body(props: TextProps) {
    return (
      <TextWrapper fontWeight={400} fontSize={16} $color={"text1"} {...props} />
    );
  },
  LargeHeader(props: TextProps) {
    return <TextWrapper fontWeight={600} fontSize={24} {...props} />;
  },
  Medi1umHeader(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={20} {...props} />;
  },
  SubHeader(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={14} {...props} />;
  },
  Blue(props: TextProps) {
    return <TextWrapper fontWeight={400} $color={"primary1"} {...props} />;
  },
  Yellow(props: TextProps) {
    return <TextWrapper fontWeight={400} $color={"yellow1"} {...props} />;
  },
  DarkGray(props: TextProps) {
    return <TextWrapper fontWeight={400} $color={"text3"} {...props} />;
  },
  Gray(props: TextProps) {
    return <TextWrapper fontWeight={400} $color={"bg3"} {...props} />;
  },
  Italic(props: TextProps) {
    return (
      <TextWrapper
        fontWeight={400}
        fontSize={12}
        fontStyle={"italic"}
        $color={"text2"}
        {...props}
      />
    );
  },
  Error({ error, ...props }: { error: boolean } & TextProps) {
    return (
      <TextWrapper
        fontWeight={400}
        $color={error ? "red1" : "text2"}
        {...props}
      />
    );
  },
};

export const FixedGlobalStyle = createGlobalStyle`
html, button {
  font-family: 'Inter', sans-serif;
  letter-spacing: -0.018em;
  font-display: fallback;
}
input, textarea {
  font-family: 'SuisseIntl', sans-serif;
}
@supports (font-variation-settings: normal) {
  html, button {
    font-family: 'Inter var', sans-serif;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

html,
body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

button {
  user-select: none;
}

html {
  font-size: 16px;
  font-variant: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
`;
//background-color: ${({ theme }) => theme.bg2};
export const ThemedGlobalStyle = createGlobalStyle`
@font-face {
  font-family: 'SuisseIntl';
  font-weight: 400;
  font-style: normal;
  src: url(${SuisseRegularTtf}) format('truetype');
  font-display: swap; 
}

html {
  color: ${({ theme }) => theme.text1};
  background: linear-gradient(0deg, #131313, #131313), linear-gradient(135.76deg, #F4ECE4 0%, #EBE8DD 98.7%), #FFFFFF;
  font-family: 'SuisseIntl', sans-serif;
  font-weight: 400 !important;
  text-transform: lowercase;
}

[data-radix-popper-content-wrapper] {
  pointer-events: none;
}
`;
/* 
body {
  min-height: 100vh;
  background-position: 0 -30vh;
  background-repeat: no-repeat;
  background-image: ${({ theme }) =>
    `radial-gradient(50% 50% at 50% 50%, ${theme.primary5} 0%, ${transparentize(1, theme.primary4)} 100%)`};
}
*/
