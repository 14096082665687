import { Field } from "../../state/swap/actions";
import { TYPE } from "../../theme";
import {
  computeSlippageAdjustedAmounts,
  computeTradePriceBreakdown,
  formatExecutionPrice,
  warningSeverity,
} from "../../utils/prices";
import { Lcd } from "../Applet";
import { ButtonError } from "../Button";
import QuestionHelper from "../QuestionHelper";
import { AutoRow, RowBetween, RowFixed } from "../Row";
import FormattedPriceImpact from "./FormattedPriceImpact";
import { StyledBalanceMaxMini, SwapCallbackError } from "./styleds";
import { Trade, TradeType } from "@beamswap/sdk";
import { useContext, useMemo, useState } from "react";
import { Repeat } from "react-feather";
import { Text } from "rebass";
import styled, { ThemeContext } from "styled-components";

const StyledLcd = styled(Lcd)`
  padding: 10px;
`;

export default function SwapModalFooter({
  trade,
  onConfirm,
  allowedSlippage,
  swapErrorMessage,
  disabledConfirm,
}: {
  trade: Trade;
  allowedSlippage: number;
  onConfirm: () => void;
  swapErrorMessage: string | undefined;
  disabledConfirm: boolean;
}) {
  const [showInverted, setShowInverted] = useState<boolean>(false);
  const theme = useContext(ThemeContext);
  const slippageAdjustedAmounts = useMemo(
    () => computeSlippageAdjustedAmounts(trade, allowedSlippage),
    [allowedSlippage, trade]
  );
  const { priceImpactWithoutFee, realizedLPFee } = useMemo(
    () => computeTradePriceBreakdown(trade),
    [trade]
  );
  const severity = warningSeverity(priceImpactWithoutFee);

  return (
    <>
      <StyledLcd>
        <RowBetween $align="center">
          <Text fontWeight={400} fontSize={12} color={theme?.text6}>
            Price
          </Text>
          <Text
            fontWeight={400}
            fontSize={12}
            color={theme?.text1}
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              textAlign: "right",
              paddingLeft: "10px",
            }}
          >
            {formatExecutionPrice(trade, showInverted)}
            <StyledBalanceMaxMini
              onClick={() => setShowInverted(!showInverted)}
            >
              <Repeat size={12} />
            </StyledBalanceMaxMini>
          </Text>
        </RowBetween>

        <RowBetween>
          <RowFixed>
            <TYPE.Black fontSize={12} fontWeight={400} color={theme?.text6}>
              {trade.tradeType === TradeType.EXACT_INPUT
                ? "Minimum received"
                : "Maximum sold"}
            </TYPE.Black>
            <QuestionHelper
              isSmall
              text="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed."
            />
          </RowFixed>
          <RowFixed>
            <TYPE.Black fontSize={12}>
              {trade.tradeType === TradeType.EXACT_INPUT
                ? slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4) ?? "-"
                : slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4) ?? "-"}
            </TYPE.Black>
            <TYPE.Black fontSize={12} marginLeft={"4px"}>
              {trade.tradeType === TradeType.EXACT_INPUT
                ? trade.outputAmount.currency.symbol
                : trade.inputAmount.currency.symbol}
            </TYPE.Black>
          </RowFixed>
        </RowBetween>
        <RowBetween>
          <RowFixed>
            <TYPE.Black color={theme?.text6} fontSize={12} fontWeight={400}>
              Price Impact
            </TYPE.Black>
            <QuestionHelper
              isSmall
              text="The difference between the market price and your price due to trade size."
            />
          </RowFixed>
          <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
        </RowBetween>
        <RowBetween>
          <RowFixed>
            <TYPE.Black fontSize={12} fontWeight={400} color={theme?.text6}>
              Liquidity Provider Fee
            </TYPE.Black>
            <QuestionHelper
              isSmall
              text="A portion of each trade (0.30%) goes to liquidity providers as a protocol incentive."
            />
          </RowFixed>
          <TYPE.Black fontSize={12}>
            {realizedLPFee
              ? realizedLPFee?.toSignificant(6) +
                " " +
                trade.inputAmount.currency.symbol
              : "-"}
          </TYPE.Black>
        </RowBetween>
      </StyledLcd>

      <AutoRow>
        <ButtonError
          onClick={onConfirm}
          disabled={disabledConfirm}
          error={severity > 2}
          style={{ margin: "10px 0 0 0" }}
          id="confirm-swap-or-send"
        >
          <Text fontSize={20} fontWeight={400}>
            {severity > 2 ? "Swap Anyway" : "Confirm Swap"}
          </Text>
        </ButtonError>

        {swapErrorMessage ? (
          <SwapCallbackError error={swapErrorMessage} />
        ) : null}
      </AutoRow>
    </>
  );
}
