import { useActiveWeb3React } from "../../hooks";
import { useIsUserAddedToken } from "../../hooks/Tokens";
import {
  useSelectedTokenList,
  WrappedTokenInfo,
} from "../../state/lists/hooks";
import {
  useAddUserToken,
  useRemoveUserAddedToken,
} from "../../state/user/hooks";
import { useCurrencyBalance } from "../../state/wallet/hooks";
import { LinkStyledButton, TYPE } from "../../theme";
import { isTokenOnList } from "../../utils";
import Column from "../Column";
import CurrencyLogo from "../CurrencyLogo";
import Loader from "../Loader";
import { RowFixed } from "../Row";
import { MouseoverTooltip } from "../Tooltip";
import { FadedSpan, MenuItem } from "./styleds";
import {
  Currency,
  CurrencyAmount,
  currencyEquals,
  DEV,
  Token,
} from "@beamswap/sdk";
import { CSSProperties, MutableRefObject, useCallback, useMemo } from "react";
import { FixedSizeList, FixedSizeListProps } from "react-window";
import { Text } from "rebass";
import styled from "styled-components";
import { Pill } from "../Pill";
import { Lcd } from "../Applet";
import { useAccount } from "wagmi";

function currencyKey(currency: Currency): string {
  return currency instanceof Token
    ? currency.address
    : currency === DEV
    ? "BEAM"
    : "";
}

const StyledBalanceText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  max-width: 5rem;
  text-overflow: ellipsis;
`;

const Tag = styled.div`
  background-color: ${({ theme }) => theme.bg3};
  color: ${({ theme }) => theme.text2};
  font-size: 14px;
  border-radius: 4px;
  padding: 0.25rem 0.3rem 0.25rem 0.3rem;
  max-width: 6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  justify-self: flex-end;
  margin-right: 4px;
`;

const StyledPill = styled(Pill)`
  border: 1px solid #131313;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.11) 0%,
      rgba(255, 255, 255, 0.25) 4.48%,
      rgba(255, 255, 255, 0) 45.18%,
      rgba(255, 255, 255, 0) 78.33%
    ),
    #242424;
  background-blend-mode: overlay, normal;
  box-shadow: 0px -2px 2px 0px rgba(255, 255, 255, 0.07) inset,
    0px 2px 2px 0px rgba(0, 0, 0, 0.25);

  display: flex;
  gap: 4px;
  width: unset;
  padding-right: 10px;
  height: 36px;
  justify-content: flex-start;
`;

const Seperator = styled.svg`
  max-width: 100%;
  position: absolute;
  bottom: 0;
`;

const StyledFixedList = styled(FixedSizeList)<FixedSizeListProps>`
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  ::-webkit-scrollbar {
    width: 1px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`;

function Balance({ balance }: { balance: CurrencyAmount }) {
  return (
    <StyledBalanceText title={balance.toExact()}>
      {balance.toSignificant(4)}
    </StyledBalanceText>
  );
}

const TagContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

function TokenTags({ currency }: { currency: Currency }) {
  if (!(currency instanceof WrappedTokenInfo)) {
    return <span />;
  }

  const tags = currency.tags;
  if (!tags || tags.length === 0) return <span />;

  const tag = tags[0];

  return (
    <TagContainer>
      <MouseoverTooltip text={tag.description}>
        <Tag key={tag.id}>{tag.name}</Tag>
      </MouseoverTooltip>
      {tags.length > 1 ? (
        <MouseoverTooltip
          text={tags
            .slice(1)
            .map(({ name, description }) => `${name}: ${description}`)
            .join("; \n")}
        >
          <Tag>...</Tag>
        </MouseoverTooltip>
      ) : null}
    </TagContainer>
  );
}

const SPAM_TOKENS = [
  "0x5bCA1699E29f2043DD2bbb5c506d2A8D1Fa84Fa7",
  "0xb8E72C4811C16dE14403FfC6dfF2c6ce63fc42D6",
  "0xb0eda892b0826f3b7042Be793Bd239D5C81d18Bc",
  "0xb3A4FE68e87dAd5aE9ee384A4E788b5816dB0047",
].map((address) => address.toLowerCase());

function CurrencyRow({
  currency,
  onSelect,
  isSelected,
  otherSelected,
  style,
}: {
  currency: Currency;
  onSelect: () => void;
  isSelected: boolean;
  otherSelected: boolean;
  style: CSSProperties;
}) {
  const { chainId } = useActiveWeb3React();
  const { address } = useAccount();
  const key = currencyKey(currency);
  const selectedTokenList = useSelectedTokenList();
  const isOnSelectedList = isTokenOnList(selectedTokenList, currency);
  const customAdded = useIsUserAddedToken(currency);
  const balance = useCurrencyBalance(address ?? undefined, currency);

  const removeToken = useRemoveUserAddedToken();
  const addToken = useAddUserToken();

  if (SPAM_TOKENS.includes(key.toLowerCase())) {
    return null;
  }

  // only show add or remove buttons if not on selected list
  return (
    <>
      <MenuItem
        style={style}
        className={`token-item-${key}`}
        onClick={() => (isSelected ? null : onSelect())}
        disabled={isSelected}
        selected={otherSelected}
      >
        <StyledPill>
          <CurrencyLogo currency={currency} size={"22px"} />
          <Text
            title={currency.name}
            fontWeight={400}
            fontSize={20}
            color="#fff"
          >
            {currency.symbol}
          </Text>
        </StyledPill>

        <Column>
          <FadedSpan>
            {!isOnSelectedList && customAdded ? (
              <TYPE.Main fontWeight={400}>
                Added by user
                <LinkStyledButton
                  onClick={(event) => {
                    event.stopPropagation();
                    if (chainId && currency instanceof Token)
                      removeToken(chainId, currency.address);
                  }}
                >
                  (Remove)
                </LinkStyledButton>
              </TYPE.Main>
            ) : null}
            {!isOnSelectedList && !customAdded ? (
              <TYPE.Main fontWeight={400}>
                Found by address
                <LinkStyledButton
                  onClick={(event) => {
                    event.stopPropagation();
                    if (currency instanceof Token) addToken(currency);
                  }}
                >
                  (Add)
                </LinkStyledButton>
              </TYPE.Main>
            ) : null}
          </FadedSpan>
        </Column>
        <TokenTags currency={currency} />
        <RowFixed style={{ justifySelf: "flex-end" }}>
          {balance ? (
            <Balance balance={balance} />
          ) : address ? (
            <Loader />
          ) : null}
        </RowFixed>

        <Seperator
          xmlns="http://www.w3.org/2000/svg"
          width="448"
          height="3"
          viewBox="0 0 448 3"
          fill="none"
        >
          <g filter="url(#filter0_d_496_1323)">
            <path
              d="M0 1.00018L448 1.00022"
              stroke="black"
              strokeDasharray="6 1"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_496_1323"
              x="0"
              y="0.500183"
              width="448"
              height="2.00003"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="1" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_496_1323"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_496_1323"
                result="shape"
              />
            </filter>
          </defs>
        </Seperator>
      </MenuItem>
    </>
  );
}

export default function CurrencyList({
  height,
  currencies,
  selectedCurrency,
  onCurrencySelect,
  otherCurrency,
  fixedListRef,
  showETH,
}: {
  height: number;
  currencies: Currency[];
  selectedCurrency?: Currency | null;
  onCurrencySelect: (currency: Currency) => void;
  otherCurrency?: Currency | null;
  fixedListRef?: MutableRefObject<FixedSizeList | undefined>;
  showETH: boolean;
}) {
  const itemData = useMemo(
    () => (showETH ? [Currency.DEV, ...currencies] : currencies),
    [currencies, showETH]
  );

  const Row = useCallback(
    ({
      data,
      index,
      style,
    }: {
      data: Currency[];
      index: number;
      style: CSSProperties;
    }) => {
      const currency: Currency = data[index];
      const isSelected = Boolean(
        selectedCurrency && currencyEquals(selectedCurrency, currency)
      );
      const otherSelected = Boolean(
        otherCurrency && currencyEquals(otherCurrency, currency)
      );
      const handleSelect = () => onCurrencySelect(currency);

      return (
        <CurrencyRow
          style={style}
          currency={currency}
          isSelected={isSelected}
          onSelect={handleSelect}
          otherSelected={otherSelected}
        />
      );
    },
    [onCurrencySelect, otherCurrency, selectedCurrency]
  );

  const itemKey = useCallback(
    (index: number, data: any) => currencyKey(data[index]),
    []
  );

  return (
    <Lcd>
      <StyledFixedList
        height={height}
        ref={fixedListRef as any}
        width="100%"
        itemData={itemData}
        itemCount={itemData.length}
        itemSize={56}
        itemKey={itemKey}
      >
        {Row}
      </StyledFixedList>
    </Lcd>
  );
}
