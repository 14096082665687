import AddressInputPanel from "../../components/AddressInputPanel";
import { ButtonPrimary } from "../../components/Button";
import { AutoColumn } from "../../components/Column";
import CurrencyInputPanel from "../../components/CurrencyInputPanel";
import Loader from "../../components/Loader";
import { AutoRow, RowBetween } from "../../components/Row";
import TokenWarningModal from "../../components/TokenWarningModal";
import { AdvancedSwapDetails } from "../../components/swap/AdvancedSwapDetails";
import ConfirmSwapModal from "../../components/swap/ConfirmSwapModal";
import TradePrice from "../../components/swap/TradePrice";
import confirmPriceImpactWithoutFee from "../../components/swap/confirmPriceImpactWithoutFee";
import {
  ArrowWrapper,
  BottomGrouping,
  SwapCallbackError,
  Wrapper,
} from "../../components/swap/styleds";
import { INITIAL_ALLOWED_SLIPPAGE } from "../../constants";
import { useCurrency } from "../../hooks/Tokens";
import store from "../../state";
import {
  ApprovalState,
  useApproveCallbackFromTrade,
} from "../../hooks/useApproveCallback";
import { useSwapCallback } from "../../hooks/useSwapCallback";
import useToggledVersion, { Version } from "../../hooks/useToggledVersion";
import useWrapCallback, { WrapType } from "../../hooks/useWrapCallback";
import { useSetSettingsMenu } from "../../state/application/hooks";
import { Field } from "../../state/swap/actions";
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState,
} from "../../state/swap/hooks";
import {
  useExpertModeManager,
  useUserDeadline,
  useUserSlippageTolerance,
} from "../../state/user/hooks";
import { LinkStyledButton } from "../../theme";
import { maxAmountSpend } from "../../utils/maxAmountSpend";
import {
  computeTradePriceBreakdown,
  warningSeverity,
} from "../../utils/prices";
import AppBody from "../AppBody";
import { ClickableText } from "../Pool/styleds";
import { Currency, CurrencyAmount, JSBI, Token, Trade } from "@beamswap/sdk";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ArrowDown } from "react-feather";
import { ReactComponent as SwapIcon } from "../../assets/svg/swap.svg";
import { useTranslation } from "react-i18next";
import { Text } from "rebass";
import styled, { ThemeContext, css } from "styled-components";
import { ConnectButton } from "../../components/ConnectButton";
import { useAccount } from "wagmi";
import useConnectedChain from "../../hooks/useConnectedChain";

const SwapLight = styled.div<{ $isActivated: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 100%;

  ${({ $isActivated }) =>
    $isActivated
      ? css`
          background: #ffffff;
          filter: drop-shadow(0px 0px 4px #00ff38)
            drop-shadow(0px 0px 1px #05ce31);
        `
      : css`
          background: #393939;
          filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.55))
            drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.1));
        `}
`;

export default function Swap() {
  const { t } = useTranslation();
  const loadedUrlParams = useDefaultsFromURLSearch();

  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId),
  ];
  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(
    false
  );
  const urlLoadedTokens: Token[] = useMemo(
    () =>
      [loadedInputCurrency, loadedOutputCurrency]?.filter(
        (c): c is Token => c instanceof Token
      ) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  );
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true);
  }, []);

  const { isSupported } = useConnectedChain();
  const { address } = useAccount();

  const theme = useContext(ThemeContext);

  // for expert mode
  const setSettingsMenu = useSetSettingsMenu();
  const [isExpertMode] = useExpertModeManager();

  // get custom setting values for user
  const [deadline] = useUserDeadline();
  const [allowedSlippage] = useUserSlippageTolerance();

  // swap state
  const { independentField, typedValue, recipient } = useSwapState();
  const {
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
  } = useDerivedSwapInfo();
  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  );
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE;
  const toggledVersion = useToggledVersion();
  const trade = showWrap
    ? undefined
    : {
        [Version.v2]: v2Trade,
      }[toggledVersion];

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount,
      }
    : {
        [Field.INPUT]:
          independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]:
          independentField === Field.OUTPUT
            ? parsedAmount
            : trade?.outputAmount,
      };

  const {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeRecipient,
  } = useSwapActionHandlers();
  const isValid = !swapInputError;
  const dependentField: Field =
    independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT;

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value);
    },
    [onUserInput]
  );
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value);
    },
    [onUserInput]
  );

  const showImportedTokenWarning = useMemo(() => {
    if (urlLoadedTokens.length === 0) return false;

    const key = Object.keys(store.getState().lists.byUrl)[0];
    const list = store.getState().lists.byUrl[key];

    if (!list.current?.tokens) return false;

    // get first key
    const isInList = list.current.tokens.some(
      (token) =>
        token.address.toLowerCase() === urlLoadedTokens[0].address.toLowerCase()
    );

    if (isInList) {
      return false;
    }

    return urlLoadedTokens.length > 0 && !dismissTokenWarning;
  }, [urlLoadedTokens, dismissTokenWarning]);

  // modal and loading
  const [
    { showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash },
    setSwapState,
  ] = useState<{
    showConfirm: boolean;
    tradeToConfirm: Trade | undefined;
    attemptingTxn: boolean;
    swapErrorMessage: string | undefined;
    txHash: string | undefined;
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  });

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ""
      : parsedAmounts[dependentField]?.toSignificant(6) ?? "",
  };

  const route = trade?.route;
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] &&
      currencies[Field.OUTPUT] &&
      parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  );
  const noRoute = !route;

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(
    trade,
    allowedSlippage
  );

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false);

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true);
    }
  }, [approval, approvalSubmitted]);

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(
    currencyBalances[Field.INPUT]
  );

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(
    trade,
    allowedSlippage,
    deadline,
    recipient
  );

  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade);

  const handleSwap = useCallback(() => {
    if (
      priceImpactWithoutFee &&
      !confirmPriceImpactWithoutFee(priceImpactWithoutFee)
    ) {
      return;
    }
    if (!swapCallback) {
      return;
    }
    setSwapState({
      attemptingTxn: true,
      tradeToConfirm,
      showConfirm,
      swapErrorMessage: undefined,
      txHash: undefined,
    });
    swapCallback()
      .then((hash) => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: undefined,
          txHash: hash,
        });
      })
      .catch((error) => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: error.message,
          txHash: undefined,
        });
      });
  }, [tradeToConfirm, priceImpactWithoutFee, showConfirm, swapCallback]);

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false);

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee);

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode);

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({
      showConfirm: false,
      tradeToConfirm,
      attemptingTxn,
      swapErrorMessage,
      txHash,
    });
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, "");
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash]);

  const handleAcceptChanges = useCallback(() => {
    setSwapState({
      tradeToConfirm: trade,
      swapErrorMessage,
      txHash,
      attemptingTxn,
      showConfirm,
    });
  }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash]);

  const handleInputSelect = useCallback(
    (inputCurrency: Currency) => {
      setApprovalSubmitted(false); // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency);
    },
    [onCurrencySelection]
  );

  const handleMaxInput = useCallback(() => {
    maxAmountInput && onUserInput(Field.INPUT, maxAmountInput.toExact());
  }, [maxAmountInput, onUserInput]);

  const handleOutputSelect = useCallback(
    (outputCurrency: Currency) =>
      onCurrencySelection(Field.OUTPUT, outputCurrency),
    [onCurrencySelection]
  );

  return (
    <>
      <TokenWarningModal
        isOpen={showImportedTokenWarning}
        tokens={urlLoadedTokens}
        onConfirm={handleConfirmTokenWarning}
      />
      <AppBody>
        <Wrapper id="swap-page">
          <ConfirmSwapModal
            isOpen={showConfirm}
            trade={trade}
            originalTrade={tradeToConfirm}
            onAcceptChanges={handleAcceptChanges}
            attemptingTxn={attemptingTxn}
            txHash={txHash}
            recipient={recipient}
            allowedSlippage={allowedSlippage}
            onConfirm={handleSwap}
            swapErrorMessage={swapErrorMessage}
            onDismiss={handleConfirmDismiss}
          />

          <AutoColumn $gap={"md"}>
            <CurrencyInputPanel
              label={
                independentField === Field.OUTPUT && !showWrap && trade
                  ? "From (estimated)"
                  : "From"
              }
              value={formattedAmounts[Field.INPUT]}
              currency={currencies[Field.INPUT]}
              onUserInput={handleTypeInput}
              onMax={handleMaxInput}
              onCurrencySelect={handleInputSelect}
              otherCurrency={currencies[Field.OUTPUT]}
              id="swap-currency-input"
            />
            <AutoColumn $justify="space-between">
              <AutoRow
                justify={isExpertMode ? "space-between" : "center"}
                style={{ padding: "0 1rem" }}
              >
                <ArrowWrapper
                  $clickable
                  onClick={() => {
                    setApprovalSubmitted(false); // reset 2 step UI for approvals
                    onSwitchTokens();
                  }}
                >
                  <SwapIcon />
                </ArrowWrapper>
                {recipient === null && !showWrap && isExpertMode ? (
                  <LinkStyledButton
                    id="add-recipient-button"
                    onClick={() => onChangeRecipient("")}
                  >
                    + Add a send (optional)
                  </LinkStyledButton>
                ) : null}
              </AutoRow>
            </AutoColumn>
            <CurrencyInputPanel
              value={formattedAmounts[Field.OUTPUT]}
              onUserInput={handleTypeOutput}
              label={
                independentField === Field.INPUT && !showWrap && trade
                  ? "To (estimated)"
                  : "To"
              }
              currency={currencies[Field.OUTPUT]}
              onCurrencySelect={handleOutputSelect}
              otherCurrency={currencies[Field.INPUT]}
              id="swap-currency-output"
            />

            {recipient !== null && !showWrap ? (
              <>
                <AutoRow justify="space-between" style={{ padding: "0 1rem" }}>
                  <ArrowWrapper $clickable={false}>
                    <ArrowDown size="16" color={theme?.text2} />
                  </ArrowWrapper>
                  <LinkStyledButton
                    id="remove-recipient-button"
                    onClick={() => onChangeRecipient(null)}
                  >
                    - Remove send
                  </LinkStyledButton>
                </AutoRow>
                <AddressInputPanel
                  id="recipient"
                  value={recipient}
                  onChange={onChangeRecipient}
                />
              </>
            ) : null}

            {!showWrap &&
              (allowedSlippage !== INITIAL_ALLOWED_SLIPPAGE || !!trade) && (
                <AdvancedSwapDetails trade={trade}>
                  <AutoColumn $gap="4px">
                    {!!trade && (
                      <RowBetween $align="center">
                        <Text
                          fontWeight={400}
                          fontSize={12}
                          color={theme?.text6}
                        >
                          {t("price")}
                        </Text>
                        <TradePrice
                          price={trade?.executionPrice}
                          showInverted={showInverted}
                          setShowInverted={setShowInverted}
                        />
                      </RowBetween>
                    )}
                    {allowedSlippage !== INITIAL_ALLOWED_SLIPPAGE && (
                      <RowBetween $align="center">
                        <Text
                          fontWeight={400}
                          fontSize={12}
                          color={theme?.text6}
                        >
                          Slippage Tolerance
                        </Text>
                        <ClickableText
                          fontWeight={400}
                          fontSize={12}
                          color={theme?.text2}
                          onClick={() => setSettingsMenu(true)}
                        >
                          {allowedSlippage / 100}%
                        </ClickableText>
                      </RowBetween>
                    )}
                  </AutoColumn>
                </AdvancedSwapDetails>
              )}
          </AutoColumn>

          <BottomGrouping>
            {!address || !isSupported ? (
              <ConnectButton buttonComponent={ButtonPrimary} />
            ) : showWrap ? (
              <ButtonPrimary
                disabled={Boolean(wrapInputError)}
                onClick={onWrap}
              >
                {wrapInputError ??
                  (wrapType === WrapType.WRAP
                    ? "wrap"
                    : wrapType === WrapType.UNWRAP
                    ? "unwrap"
                    : null)}
              </ButtonPrimary>
            ) : noRoute && userHasSpecifiedInputOutput ? (
              <ButtonPrimary disabled>
                <Text fontSize={16} fontWeight={400}>
                  {t("insufficientLiquidityForThisTrade")}
                </Text>
              </ButtonPrimary>
            ) : showApproveFlow ? (
              <RowBetween style={{ gap: 10 }}>
                <ButtonPrimary
                  onClick={approveCallback}
                  disabled={
                    approval !== ApprovalState.NOT_APPROVED || approvalSubmitted
                  }
                  icon={
                    approval === ApprovalState.PENDING ? (
                      <Loader stroke="white" />
                    ) : (
                      undefined
                    )
                  }
                >
                  {approval === ApprovalState.PENDING
                    ? "approving"
                    : approvalSubmitted && approval === ApprovalState.APPROVED
                    ? "approved"
                    : "approve"}
                </ButtonPrimary>
                <ButtonPrimary
                  onClick={() => {
                    if (isExpertMode) {
                      handleSwap();
                    } else {
                      setSwapState({
                        tradeToConfirm: trade,
                        attemptingTxn: false,
                        swapErrorMessage: undefined,
                        showConfirm: true,
                        txHash: undefined,
                      });
                    }
                  }}
                  id="swap-button"
                  disabled={
                    !isValid ||
                    approval !== ApprovalState.APPROVED ||
                    (priceImpactSeverity > 3 && !isExpertMode)
                  }
                  icon={
                    <SwapLight
                      $isActivated={approval === ApprovalState.APPROVED}
                    />
                  }
                  // error={isValid && priceImpactSeverity > 2}
                >
                  <Text fontSize={16} fontWeight={400}>
                    {priceImpactSeverity > 3 && !isExpertMode
                      ? `price impact high`
                      : `swap${priceImpactSeverity > 2 ? " anyway" : ""}`}
                  </Text>
                </ButtonPrimary>
              </RowBetween>
            ) : (
              <ButtonPrimary
                onClick={() => {
                  if (isExpertMode) {
                    handleSwap();
                  } else {
                    setSwapState({
                      tradeToConfirm: trade,
                      attemptingTxn: false,
                      swapErrorMessage: undefined,
                      showConfirm: true,
                      txHash: undefined,
                    });
                  }
                }}
                id="swap-button"
                disabled={
                  !isValid ||
                  (priceImpactSeverity > 3 && !isExpertMode) ||
                  !!swapCallbackError
                }
                // error={isValid && priceImpactSeverity > 2 && !swapCallbackError}
              >
                <Text fontSize={20} fontWeight={400}>
                  {swapInputError
                    ? swapInputError
                    : priceImpactSeverity > 3 && !isExpertMode
                    ? `price impact too high`
                    : `swap${priceImpactSeverity > 2 ? " anyway" : ""}`}
                </Text>
              </ButtonPrimary>
            )}
            {isExpertMode && swapErrorMessage ? (
              <SwapCallbackError error={swapErrorMessage} />
            ) : null}
          </BottomGrouping>
        </Wrapper>
      </AppBody>
    </>
  );
}
