import { CardProps, Text } from "rebass";
import { Box } from "rebass/styled-components";
import styled from "styled-components";
import { Lcd } from "../Applet";

const Card = styled(Box)<{
  $padding?: string;
  $border?: string;
  $borderRadius?: string;
}>`
  width: 100%;
  border-radius: 16px;
  padding: 1.25rem;
  padding: ${({ $padding }) => $padding};
  border: ${({ $border }) => $border};
  border-radius: ${({ $borderRadius }) => $borderRadius};
`;
export default Card;

export const LightCard = styled(Lcd)<{
  $padding?: string;
  $border?: string;
}>`
  width: 100%;
  border-radius: 16px;
  padding: 1.25rem;
  padding: ${({ $padding }) => $padding};
  border: ${({ $border }) => $border};
`;

export const GreyCard = styled(Card)`
  background-color: ${({ theme }) => theme.bg1};
`;

export const OutlineCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.bg3};
`;

export const YellowCard = styled(Card)`
  background-color: rgba(243, 132, 30, 0.05);
  color: ${({ theme }) => theme.yellow2};
  font-weight: 400;
`;

export const PinkCard = styled(Card)`
  background-color: rgba(255, 0, 122, 0.03);
  color: ${({ theme }) => theme.primary1};
  font-weight: 400;
`;

export const BlueCard = ({ children }: CardProps) => {
  return (
    <Lcd style={{ padding: "12px", color: "#fff !important", width: "100%" }}>
      <Text fontWeight={400} color="#fff" fontSize="12px">
        {children}
      </Text>
    </Lcd>
  );
};
