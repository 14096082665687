import CurrencyLogo from "../CurrencyLogo";
import { Currency } from "@beamswap/sdk";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div<{ $margin: boolean; $sizeRaw: number }>`
  position: relative;
  display: flex;
  flex-direction: row;
  margin-right: ${({ $sizeRaw, $margin }) =>
    $margin && ($sizeRaw / 3 + 8).toString() + "px"};
`;

interface DoubleCurrencyLogoProps {
  margin?: boolean;
  size?: number;
  currency0?: Currency;
  currency1?: Currency;
}

const HigherLogo = styled(CurrencyLogo)`
  z-index: 2;
`;
const CoveredLogo = styled(CurrencyLogo)<{ $sizeRaw: number }>`
  position: absolute;
  left: ${({ $sizeRaw }) => ($sizeRaw / 2).toString() + "px"};
`;

export default function DoubleCurrencyLogo({
  currency0,
  currency1,
  size = 16,
  margin = false,
}: DoubleCurrencyLogoProps) {
  return (
    <Wrapper $sizeRaw={size} $margin={margin}>
      {currency0 && (
        <HigherLogo currency={currency0} size={size.toString() + "px"} />
      )}
      {currency1 && (
        <CoveredLogo
          currency={currency1}
          size={size.toString() + "px"}
          $sizeRaw={size}
        />
      )}
    </Wrapper>
  );
}
