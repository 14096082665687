import * as Fathom from "fathom-client";
import { useEffect } from "react";
import { useHistory } from "react-router";

/**
 * Fathom is our tracking solution that doesn't require any cookies and thus doesn't need any consent
 * To learn more: https://usefathom.com/
 */
export const FathomScript = () => {
  const history = useHistory();

  useEffect(() => {
    if (!process.env.REACT_APP_HOSTNAME || !process.env.REACT_APP_FATHOM_ID)
      return;

    /**
     *  Initialize Fathom when the app loads
     *  Example: yourdomain.com
     *  - Do not include https://
     *  - This must be an exact match of your domain.
     *  - If you're using www. for your domain, make sure you include that here.
     */
    Fathom.load(process.env.REACT_APP_FATHOM_ID, {
      includedDomains: [process.env.REACT_APP_HOSTNAME],
    });

    const onRouteChangeComplete = () => Fathom.trackPageview();

    // Record a pageview when route changes
    history.listen(onRouteChangeComplete);
    // It's not possible to unsubscribe from the listen event when using react-router
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
