import { ButtonPrimary } from "../Button";
import { Footer } from "./Footer";
import { LcdMarquee } from "./LcdMarquee";
import styled from "styled-components";
import { ConnectButton } from "../ConnectButton";

const StyledFooter = styled(Footer)`
  position: absolute;
  bottom: 18px;
  left: 29px;
  right: 29px;
`;

const Container = styled.div`
  height: 380px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 40px;
`;

export const ConnectWalletApplet = () => {
  return (
    <Container>
      <LcdMarquee />
      <ConnectButton buttonComponent={ButtonPrimary} isSmall={false} />
      <StyledFooter />
    </Container>
  );
};
