import styled from "styled-components";
import { LightCard } from "../../components/Card";
import { AutoColumn, ColumnCenter } from "../../components/Column";
import { FindPoolTabs } from "../../components/NavigationTabs";
import { MinimalPositionCard } from "../../components/PositionCard";
import Row from "../../components/Row";
import CurrencySearchModal from "../../components/SearchModal/CurrencySearchModal";
import { PairState, usePair } from "../../data/Reserves";
import { usePairAdder } from "../../state/user/hooks";
import { useTokenBalance } from "../../state/wallet/hooks";
import { StyledInternalLink } from "../../theme";
import { currencyId } from "../../utils/currencyId";
import AppBody from "../AppBody";
import { Dots } from "../Pool/styleds";
import { Currency, DEV, JSBI, TokenAmount } from "@beamswap/sdk";
import { useCallback, useEffect, useState } from "react";
import { Text } from "rebass";
import { Pill } from "../../components/Pill";
import { ReactComponent as PlusIcon } from "../../assets/svg/plus.svg";
import CurrencySelector from "../../components/CurrencySelector";
import { useAccount } from "wagmi";

enum Fields {
  TOKEN0 = 0,
  TOKEN1 = 1,
}

const StyledPill = styled(Pill)`
  width: 38px;
  height: 38px;
  cursor: default;
`;

const StyledColumn = styled.div<{ $align: string }>`
  display: flex;
  width: calc(100% / 3);
  justify-content: ${({ $align }) => $align};
`;

export default function PoolFinder() {
  const { address } = useAccount();

  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [activeField, setActiveField] = useState<number>(Fields.TOKEN1);

  const [currency0, setCurrency0] = useState<Currency | null>(DEV);
  const [currency1, setCurrency1] = useState<Currency | null>(null);

  const [pairState, pair] = usePair(
    currency0 ?? undefined,
    currency1 ?? undefined
  );
  const addPair = usePairAdder();

  useEffect(() => {
    if (pair) {
      addPair(pair);
    }
  }, [pair, addPair]);

  const validPairNoLiquidity: boolean =
    pairState === PairState.NOT_EXISTS ||
    Boolean(
      pairState === PairState.EXISTS &&
        pair &&
        JSBI.equal(pair.reserve0.raw, JSBI.BigInt(0)) &&
        JSBI.equal(pair.reserve1.raw, JSBI.BigInt(0))
    );

  const position: TokenAmount | undefined = useTokenBalance(
    address ?? undefined,
    pair?.liquidityToken
  );
  const hasPosition = Boolean(
    position && JSBI.greaterThan(position.raw, JSBI.BigInt(0))
  );

  const handleCurrencySelect = useCallback(
    (currency: Currency) => {
      if (activeField === Fields.TOKEN0) {
        setCurrency0(currency);
      } else {
        setCurrency1(currency);
      }
    },
    [activeField]
  );

  const handleSearchDismiss = useCallback(() => {
    setShowSearch(false);
  }, [setShowSearch]);

  const prerequisiteMessage = (
    <LightCard $padding="45px 10px">
      <Text textAlign="center">
        {!address
          ? "Connect to a wallet to find pools"
          : "Select a token to find your liquidity."}
      </Text>
    </LightCard>
  );

  return (
    <AppBody>
      <FindPoolTabs />
      <AutoColumn $gap="md">
        <Row>
          <StyledColumn $align="flex-start">
            <CurrencySelector
              currency={currency0}
              onClick={() => {
                setShowSearch(true);
                setActiveField(Fields.TOKEN0);
              }}
            />
          </StyledColumn>

          <StyledColumn $align="center">
            <StyledPill $isSelected as="div">
              <PlusIcon />
            </StyledPill>
          </StyledColumn>

          <StyledColumn $align="flex-end">
            <CurrencySelector
              currency={currency1}
              onClick={() => {
                setShowSearch(true);
                setActiveField(Fields.TOKEN1);
              }}
            />
          </StyledColumn>
        </Row>

        {hasPosition && (
          <ColumnCenter
            style={{
              justifyItems: "center",
              backgroundColor: "",
              padding: "12px 0px",
              borderRadius: "12px",
            }}
          >
            <Text textAlign="center" fontWeight={400}>
              Pool Found!
            </Text>
          </ColumnCenter>
        )}

        {currency0 && currency1 ? (
          pairState === PairState.EXISTS ? (
            hasPosition && pair ? (
              <MinimalPositionCard pair={pair} border="1px solid #CED0D9" />
            ) : (
              <LightCard $padding="45px 10px">
                <AutoColumn $gap="sm" $justify="center">
                  <Text textAlign="center">
                    You don’t have liquidity in this pool yet.
                  </Text>
                  <StyledInternalLink
                    to={`/add/${currencyId(currency0)}/${currencyId(
                      currency1
                    )}`}
                  >
                    <Text textAlign="center">add liquidity.</Text>
                  </StyledInternalLink>
                </AutoColumn>
              </LightCard>
            )
          ) : validPairNoLiquidity ? (
            <LightCard $padding="45px 10px">
              <AutoColumn $gap="sm" $justify="center">
                <Text textAlign="center">No pool found.</Text>
                <StyledInternalLink
                  to={`/add/${currencyId(currency0)}/${currencyId(currency1)}`}
                >
                  Create pool.
                </StyledInternalLink>
              </AutoColumn>
            </LightCard>
          ) : pairState === PairState.INVALID ? (
            <LightCard $padding="45px 10px">
              <AutoColumn $gap="sm" $justify="center">
                <Text textAlign="center" fontWeight={400}>
                  Invalid pair.
                </Text>
              </AutoColumn>
            </LightCard>
          ) : pairState === PairState.LOADING ? (
            <LightCard $padding="45px 10px">
              <AutoColumn $gap="sm" $justify="center">
                <Text textAlign="center">
                  Loading
                  <Dots />
                </Text>
              </AutoColumn>
            </LightCard>
          ) : null
        ) : (
          prerequisiteMessage
        )}
      </AutoColumn>

      <CurrencySearchModal
        isOpen={showSearch}
        onCurrencySelect={handleCurrencySelect}
        onDismiss={handleSearchDismiss}
        showCommonBases
        selectedCurrency={
          (activeField === Fields.TOKEN0 ? currency1 : currency0) ?? undefined
        }
      />
    </AppBody>
  );
}
