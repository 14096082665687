import Circle from "../../assets/images/blue-loader.svg";
import { useActiveWeb3React } from "../../hooks";
import { ExternalLink } from "../../theme";
import { CloseIcon, Spinner } from "../../theme/components";
import { getEtherscanLink } from "../../utils";
import { Lcd } from "../Applet";
import { AutoColumn, ColumnCenter } from "../Column";
import Modal from "../Modal";
import { RowBetween } from "../Row";
import { ChainId } from "@beamswap/sdk";
import React, { useContext } from "react";
import { AlertTriangle, ArrowUpCircle } from "react-feather";
import { Text } from "rebass";
import styled, { ThemeContext } from "styled-components";

const Wrapper = styled.div`
  width: 100%;
`;

const ConfirmedIcon = styled(ColumnCenter)`
  padding: 20px 0;
`;

const CustomLightSpinner = styled(Spinner)<{ size: string }>`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
`;

const StyledLcd = styled(Lcd)`
  padding-left: 15px;
  padding-right: 15px;

  margin-bottom: 12px;
  padding-bottom: 14px;
`;

const ConfirmationLcd = styled(StyledLcd)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  min-height: 310px;
`;

function ConfirmationPendingContent({
  onDismiss,
  pendingText,
}: {
  onDismiss: () => void;
  pendingText: string;
}) {
  return (
    <Wrapper>
      <RowBetween sx={{ mb: 12 }}>
        <Text fontWeight={400} fontSize={14}>
          Confirm transaction
        </Text>
        <CloseIcon onClick={onDismiss} />
      </RowBetween>
      <ConfirmationLcd>
        <ConfirmedIcon>
          <CustomLightSpinner src={Circle} alt="loader" size={"18px"} />
        </ConfirmedIcon>
        <AutoColumn $justify={"center"}>
          <AutoColumn $gap="12px" $justify={"center"}>
            <Text fontWeight={600} fontSize={12} color="" textAlign="center">
              {pendingText}
            </Text>
          </AutoColumn>
          <Text fontSize={12} color="#565A69" textAlign="center">
            Confirm this transaction in your wallet
          </Text>
        </AutoColumn>
      </ConfirmationLcd>
    </Wrapper>
  );
}

function TransactionSubmittedContent({
  onDismiss,
  chainId,
  hash,
}: {
  onDismiss: () => void;
  hash: string | undefined;
  chainId: ChainId;
}) {
  const theme = useContext(ThemeContext);

  return (
    <Wrapper>
      <RowBetween sx={{ mb: 12 }}>
        <Text fontWeight={400} fontSize={14}>
          Transaction submitted
        </Text>
        <CloseIcon onClick={onDismiss} />
      </RowBetween>
      <ConfirmationLcd>
        <ConfirmedIcon>
          <ArrowUpCircle strokeWidth={0.5} size={18} color={theme?.primary1} />
        </ConfirmedIcon>
        <AutoColumn $gap="12px" $justify={"center"}>
          <AutoColumn $justify={"center"}>
            <AutoColumn $gap="12px" $justify={"center"}>
              <Text fontWeight={600} fontSize={12} color="" textAlign="center">
                Transaction Submitted
              </Text>
            </AutoColumn>
            {chainId && hash && (
              <ExternalLink
                href={getEtherscanLink(chainId, hash, "transaction")}
              >
                <Text fontWeight={400} fontSize={12} color={theme?.primary1}>
                  View on block explorer
                </Text>
              </ExternalLink>
            )}
          </AutoColumn>
        </AutoColumn>
      </ConfirmationLcd>
    </Wrapper>
  );
}

export function ConfirmationModalContent({
  title,
  bottomContent,
  onDismiss,
  topContent,
}: {
  title: string;
  onDismiss: () => void;
  topContent: () => React.ReactNode;
  bottomContent: () => React.ReactNode;
}) {
  return (
    <Wrapper>
      <RowBetween sx={{ mb: 12 }}>
        <Text fontWeight={400} fontSize={14}>
          {title}
        </Text>
        <CloseIcon onClick={onDismiss} />
      </RowBetween>

      <StyledLcd>{topContent()}</StyledLcd>

      {bottomContent()}
    </Wrapper>
  );
}

export function TransactionErrorContent({
  message,
  onDismiss,
}: {
  message: string;
  onDismiss: () => void;
}) {
  const theme = useContext(ThemeContext);
  // return (
  //   <Wrapper>
  //     <Section>
  //       <RowBetween>
  //         <Text fontWeight={400}  fontSize={20}>
  //           Error
  //         </Text>
  //         <CloseIcon onClick={onDismiss} />
  //       </RowBetween>
  //       <AutoColumn style={{ marginTop: 20, padding: '2rem 0' }} gap="24px" justify="center">
  //         <AlertTriangle color={theme.red1} style={{ strokeWidth: 1.5 }} size={64} />
  //         <Text fontWeight={400}  fontSize={16} color={theme.red1} style={{ textAlign: 'center', width: '85%' }}>
  //           {message}
  //         </Text>
  //       </AutoColumn>
  //     </Section>
  //     <BottomSection gap="12px">
  //       <ButtonPrimary onClick={onDismiss}>Dismiss</ButtonPrimary>
  //     </BottomSection>
  //   </Wrapper>
  // )

  return (
    <Wrapper>
      <RowBetween sx={{ mb: 12 }}>
        <Text fontWeight={400} fontSize={14}>
          Error
        </Text>
        <CloseIcon onClick={onDismiss} />
      </RowBetween>
      <ConfirmationLcd>
        <ConfirmedIcon>
          <AlertTriangle
            color={theme?.red1}
            style={{ strokeWidth: 1.5 }}
            size={24}
          />
        </ConfirmedIcon>
        <AutoColumn $gap="12px" $justify={"center"}>
          <AutoColumn $justify={"center"}>
            <AutoColumn $gap="12px" $justify={"center"}>
              <Text
                fontWeight={400}
                fontSize={12}
                color={theme?.red1}
                style={{ textAlign: "center", width: "85%" }}
              >
                {message}
              </Text>
            </AutoColumn>
          </AutoColumn>
        </AutoColumn>
      </ConfirmationLcd>
    </Wrapper>
  );
}

interface ConfirmationModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  hash: string | undefined;
  content: () => React.ReactNode;
  attemptingTxn: boolean;
  pendingText: string;
}

export default function TransactionConfirmationModal({
  isOpen,
  onDismiss,
  attemptingTxn,
  hash,
  pendingText,
  content,
}: ConfirmationModalProps) {
  const { chainId } = useActiveWeb3React();

  if (!chainId) return null;

  // confirmation screen
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90}>
      {attemptingTxn ? (
        <ConfirmationPendingContent
          onDismiss={onDismiss}
          pendingText={pendingText}
        />
      ) : hash ? (
        <TransactionSubmittedContent
          chainId={chainId}
          hash={hash}
          onDismiss={onDismiss}
        />
      ) : (
        content()
      )}
    </Modal>
  );
}
