import styled, { css } from "styled-components";

export const Pill = styled.button<{ $isSelected?: boolean }>`
  border-radius: 9999px;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 90px;
  height: 30px;
  font-size: 14px;
  padding-bottom: 2px;

  cursor: pointer;
  border: 1px solid #131313;

  ${({ $isSelected }) =>
    $isSelected
      ? css`
          background: linear-gradient(
              178deg,
              rgba(255, 255, 255, 0.11) 1.34%,
              rgba(255, 255, 255, 0.25) 5.7%,
              rgba(255, 255, 255, 0) 45.31%,
              rgba(255, 255, 255, 0) 77.57%
            ),
            #242424;
          background-blend-mode: overlay, normal;
          box-shadow: 0px -2px 2px 0px rgba(255, 255, 255, 0.07) inset,
            0px 2px 2px 0px rgba(0, 0, 0, 0.25);

          color: #f1f1f1;
        `
      : css`
          background: linear-gradient(
              178deg,
              rgba(255, 255, 255, 0.11) 1.34%,
              rgba(255, 255, 255, 0.25) 5.7%,
              rgba(255, 255, 255, 0) 45.31%,
              rgba(255, 255, 255, 0) 77.57%
            ),
            #131313;
          background-blend-mode: overlay, normal;
          box-shadow: 0px -2px 2px 0px rgba(255, 255, 255, 0.07) inset,
            0px 2px 2px 0px rgba(0, 0, 0, 0.25);
          color: #6d6d6d;
        `}
`;
