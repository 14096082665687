import { useActiveWeb3React } from "../../hooks";
import { useAllTokens, useToken } from "../../hooks/Tokens";
import { isAddress } from "../../utils";
import { Lcd } from "../Applet";
import Column from "../Column";
import { RowBetween } from "../Row";
import CommonBases from "./CommonBases";
import CurrencyList from "./CurrencyList";
import { filterTokens } from "./filtering";
import { useTokenComparator } from "./sorting";
import { SearchInput } from "./styleds";
import { Currency, DEV, Token } from "@beamswap/sdk";
import {
  ChangeEventHandler,
  KeyboardEvent,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import { Text } from "rebass";
import styled from "styled-components";
import { ReactComponent as CrossIcon } from "../../assets/svg/cross.svg";

const ResettedButton = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
`;

interface CurrencySearchProps {
  isOpen: boolean;
  onDismiss: () => void;
  selectedCurrency?: Currency | null;
  onCurrencySelect: (currency: Currency) => void;
  otherSelectedCurrency?: Currency | null;
  showCommonBases?: boolean;
  onChangeList: () => void;
}

const invertSearchOrder = false;

export function CurrencySearch({
  selectedCurrency,
  onCurrencySelect,
  otherSelectedCurrency,
  showCommonBases,
  onDismiss,
  isOpen,
  onChangeList,
}: CurrencySearchProps) {
  const { t } = useTranslation();
  const { chainId } = useActiveWeb3React();

  const fixedList = useRef<FixedSizeList>();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const allTokens = useAllTokens();

  // if they input an address, use it
  const isAddressSearch = isAddress(searchQuery);
  const searchToken = useToken(searchQuery);

  const showETH: boolean = useMemo(() => {
    const s = searchQuery.toLowerCase().trim();
    return s === "" || s === "d" || s === "de" || s === "dev";
  }, [searchQuery]);

  const tokenComparator = useTokenComparator(invertSearchOrder);

  const filteredTokens: Token[] = useMemo(() => {
    if (isAddressSearch) return searchToken ? [searchToken] : [];
    return filterTokens(Object.values(allTokens), searchQuery);
  }, [isAddressSearch, searchToken, allTokens, searchQuery]);

  const filteredSortedTokens: Token[] = useMemo(() => {
    if (searchToken) return [searchToken];
    const sorted = filteredTokens.sort(tokenComparator);
    const symbolMatch = searchQuery
      .toLowerCase()
      .split(/\s+/)
      .filter((s) => s.length > 0);
    if (symbolMatch.length > 1) return sorted;

    return [
      ...(searchToken ? [searchToken] : []),
      // sort any exact symbol matches first
      ...sorted.filter(
        (token) => token.symbol?.toLowerCase() === symbolMatch[0]
      ),
      ...sorted.filter(
        (token) => token.symbol?.toLowerCase() !== symbolMatch[0]
      ),
    ];
  }, [filteredTokens, searchQuery, searchToken, tokenComparator]);

  const handleCurrencySelect = useCallback(
    (currency: Currency) => {
      onCurrencySelect(currency);
      onDismiss();
    },
    [onDismiss, onCurrencySelect]
  );

  // clear the input on open
  useEffect(() => {
    if (isOpen) setSearchQuery("");
  }, [isOpen]);

  // manage focus on modal show
  const inputRef = useRef<HTMLInputElement>();
  const handleInput = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const input = event.target.value;

      if (
        input.toLowerCase() ===
        "0x5bCA1699E29f2043DD2bbb5c506d2A8D1Fa84Fa7".toLowerCase()
      ) {
        throw new Error();
      }
      const checksummedInput = isAddress(input);
      setSearchQuery(checksummedInput || input);
      fixedList.current?.scrollTo(0);
    },
    []
  );

  const handleEnter = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        const s = searchQuery.toLowerCase().trim();
        if (s === "dev") {
          handleCurrencySelect(DEV);
        } else if (filteredSortedTokens.length > 0) {
          if (
            filteredSortedTokens[0].symbol?.toLowerCase() ===
              searchQuery.trim().toLowerCase() ||
            filteredSortedTokens.length === 1
          ) {
            handleCurrencySelect(filteredSortedTokens[0]);
          }
        }
      }
    },
    [filteredSortedTokens, handleCurrencySelect, searchQuery]
  );

  return (
    <Column style={{ width: "100%", flex: "1 1", height: "100%" }}>
      <Column>
        <RowBetween sx={{ mb: "19px" }}>
          <Text fontWeight={400} fontSize={12} color="#F1F1F1">
            Select a token
          </Text>
          <ResettedButton onClick={onDismiss}>
            <CrossIcon />
          </ResettedButton>
        </RowBetween>

        <Lcd>
          <SearchInput
            type="text"
            id="token-search-input"
            placeholder={t("tokenSearchPlaceholder").toLowerCase()}
            value={searchQuery}
            ref={inputRef as RefObject<HTMLInputElement>}
            onChange={handleInput}
            onKeyDown={handleEnter}
          />
        </Lcd>
        {showCommonBases && (
          <CommonBases
            chainId={chainId}
            onSelect={handleCurrencySelect}
            selectedCurrency={selectedCurrency}
          />
        )}
        <RowBetween sx={{ mt: "9px", mb: "9px" }}>
          <Text fontSize={10} color="#6D6D6D">
            Token Name
          </Text>
        </RowBetween>
      </Column>

      <div style={{ flex: "1" }}>
        <AutoSizer disableWidth>
          {({ height }) => (
            <CurrencyList
              height={height}
              showETH={showETH}
              currencies={filteredSortedTokens}
              onCurrencySelect={handleCurrencySelect}
              otherCurrency={otherSelectedCurrency}
              selectedCurrency={selectedCurrency}
              fixedListRef={fixedList}
            />
          )}
        </AutoSizer>
      </div>

      {/* TODO add token lists when we need them */}
      {/* <Card>
        <RowBetween>
          <ResettedButton onClick={onChangeList}>
            <Text fontSize={10} color="#6D6D6D">
              manage token lists
            </Text>
          </ResettedButton>
        </RowBetween>
      </Card> */}
    </Column>
  );
}
