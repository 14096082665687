import React from "react";
import styled from "styled-components";

const Container = styled.svg`
  max-width: 100%;
`;

export const Seperator = ({ className }: { className?: string }) => {
  return (
    <Container
      xmlns="http://www.w3.org/2000/svg"
      width="448"
      height="3"
      viewBox="0 0 448 3"
      fill="none"
      className={className}
    >
      <g filter="url(#filter0_d_496_1323)">
        <path d="M0 1.00018L448 1.00022" stroke="black" strokeDasharray="6 1" />
      </g>
      <defs>
        <filter
          id="filter0_d_496_1323"
          x="0"
          y="0.500183"
          width="448"
          height="2.00003"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_496_1323"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_496_1323"
            result="shape"
          />
        </filter>
      </defs>
    </Container>
  );
};
