import { Background } from "../components/Background";
import Header from "../components/Header";
import Popups from "../components/Popups";
import Web3ReactManager from "../components/Web3ReactManager";
import { AppDispatch, AppState } from "../state";
import { acceptListUpdate } from "../state/lists/actions";
import { useSelectedListUrl } from "../state/lists/hooks";
import DarkModeQueryParamReader from "../theme/DarkModeQueryParamReader";
import { FathomScript } from "../utils/FathomScript";
import AddLiquidity from "./AddLiquidity";
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity,
} from "./AddLiquidity/redirects";
import Pool from "./Pool";
import PoolFinder from "./PoolFinder";
import RemoveLiquidity from "./RemoveLiquidity";
import { RedirectOldRemoveLiquidityPathStructure } from "./RemoveLiquidity/redirects";
import Swap from "./Swap";
import { RedirectPathToSwapOnly, RedirectToSwap } from "./Swap/redirects";
import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HashRouter, Route, Switch } from "react-router-dom";
import styled from "styled-components";

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
  min-height: 100vh;
  min-height: 100svh;
`;

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 160px;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      padding: 4px 0;
  `};

  z-index: 1;
`;

export default function App() {
  // handle token list update if necessary
  const dispatch = useDispatch<AppDispatch>();
  const listsByUrl = useSelector<AppState, AppState["lists"]["byUrl"]>(
    (state) => state.lists.byUrl
  );
  const selectedListUrl = useSelectedListUrl();

  useEffect(() => {
    if (selectedListUrl) {
      const { pendingUpdate: pending } = listsByUrl[selectedListUrl];

      if (pending) {
        try {
          dispatch(acceptListUpdate(selectedListUrl));
        } catch (e) {
          /* */
        }
      }
    }
  }, [selectedListUrl, listsByUrl, dispatch]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      // TODO: add origin
      if (event.origin !== "https://parent.origin.com") return;

      window.ethereum = event.data;
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <Suspense fallback={null}>
      <HashRouter>
        <FathomScript />
        <Route component={DarkModeQueryParamReader} />
        <React.Fragment>
          <AppWrapper>
            <Background />
            <HeaderWrapper>
              <Header />
            </HeaderWrapper>
            <BodyWrapper>
              <Popups />
              <Web3ReactManager>
                <Switch>
                  <Route exact strict path="/swap" component={Swap} />
                  <Route
                    exact
                    strict
                    path="/swap/:outputCurrency"
                    component={RedirectToSwap}
                  />
                  <Route
                    exact
                    strict
                    path="/send"
                    component={RedirectPathToSwapOnly}
                  />
                  <Route exact strict path="/find" component={PoolFinder} />
                  <Route exact strict path="/pool" component={Pool} />
                  <Route
                    exact
                    strict
                    path="/create"
                    component={RedirectToAddLiquidity}
                  />
                  <Route exact path="/add" component={AddLiquidity} />
                  <Route
                    exact
                    path="/add/:currencyIdA"
                    component={RedirectOldAddLiquidityPathStructure}
                  />
                  <Route
                    exact
                    path="/add/:currencyIdA/:currencyIdB"
                    component={RedirectDuplicateTokenIds}
                  />
                  <Route
                    exact
                    strict
                    path="/remove/:tokens"
                    component={RedirectOldRemoveLiquidityPathStructure}
                  />
                  <Route
                    exact
                    strict
                    path="/remove/:currencyIdA/:currencyIdB"
                    component={RemoveLiquidity}
                  />
                  <Route component={RedirectPathToSwapOnly} />
                </Switch>
              </Web3ReactManager>
            </BodyWrapper>
          </AppWrapper>
        </React.Fragment>
      </HashRouter>
    </Suspense>
  );
}
