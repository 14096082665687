import { ChainId } from "@beamswap/sdk";
import { useConfig, useNetwork } from "wagmi";

const useConnectedChain = () => {
  const network = useNetwork();
  const { chains } = useConfig();

  return {
    chainId: network.chain?.id as ChainId | undefined,
    isSupported: !!chains?.find((chain) => chain.id === network.chain?.id),
  };
};

export default useConnectedChain;
