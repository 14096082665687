import { NetworkContextName } from "./constants";
import "./i18n";
import App from "./pages/App";
import store from "./state";
import ApplicationUpdater from "./state/application/updater";
import ListsUpdater from "./state/lists/updater";
import MulticallUpdater from "./state/multicall/updater";
import TransactionUpdater from "./state/transactions/updater";
import UserUpdater from "./state/user/updater";
import ThemeProvider, { FixedGlobalStyle, ThemedGlobalStyle } from "./theme";
import getLibrary from "./utils/getLibrary";
import { createWeb3ReactRoot, Web3ReactProvider } from "@web3-react/core";
import "inter-ui";
import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { WagmiConfig, createConfig, configureChains } from "wagmi";
import { NETWORKS } from "./constants/network";
import { publicProvider } from "wagmi/providers/public";
import {
  getDefaultWallets,
  RainbowKitProvider,
  darkTheme,
} from "@rainbow-me/rainbowkit";

import "@rainbow-me/rainbowkit/styles.css";

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);

if ("ethereum" in window) {
  (window.ethereum as any).autoRefreshOnNetworkChange = false;
}

const { chains, publicClient } = configureChains(
  [NETWORKS[4337], NETWORKS[13337]],
  [publicProvider()]
);

const { connectors } = getDefaultWallets({
  appName: "Beam Swap",
  projectId: "e881671dba6634c60070cf3887d4bd5f",
  chains,
});

const config = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});

function Updaters() {
  return (
    <>
      <ListsUpdater />
      <UserUpdater />
      <ApplicationUpdater />
      <TransactionUpdater />
      <MulticallUpdater />
    </>
  );
}

ReactDOM.render(
  <StrictMode>
    <FixedGlobalStyle />
    <WagmiConfig config={config}>
      <RainbowKitProvider
        chains={chains}
        theme={darkTheme({
          accentColor: "#444",
          accentColorForeground: "white",
          borderRadius: "small",
          fontStack: "system",
          overlayBlur: "small",
        })}
      >
        <Web3ReactProvider getLibrary={getLibrary}>
          <Web3ProviderNetwork getLibrary={getLibrary}>
            <Provider store={store}>
              <Updaters />
              <ThemeProvider>
                <ThemedGlobalStyle />
                <App />
              </ThemeProvider>
            </Provider>
          </Web3ProviderNetwork>
        </Web3ReactProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  </StrictMode>,
  document.getElementById("root")
);
