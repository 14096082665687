import { ComponentProps, FC, ReactNode } from "react";
import { RowBetween } from "../Row";
import { darken, lighten } from "polished";
import { ChevronDown } from "react-feather";
import { Button as RebassButton, ButtonProps } from "rebass/styled-components";
import styled from "styled-components";

const Base = styled(RebassButton)<{
  $padding?: string;
  $width?: string;
  $borderRadius?: string;
  $altDisabledStyle?: boolean;
}>`
  padding: ${({ $padding }) => ($padding ? $padding : "18px")};
  width: ${({ $width }) => ($width ? $width : "100%")};
  font-weight: 400;
  text-align: center;
  border-radius: ${({ $borderRadius }) => $borderRadius && $borderRadius};
  outline: none;
  border: 1px solid transparent;
  color: white;
  text-decoration: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  &:disabled {
    cursor: auto;
  }

  > * {
    user-select: none;
  }
`;

export const StyledButtonPrimary = styled(Base)`
  border-radius: 9999px;
  border: 1px solid #131313;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.11) 0%,
      rgba(255, 255, 255, 0.25) 4.48%,
      rgba(255, 255, 255, 0) 45.18%,
      rgba(255, 255, 255, 0) 78.33%
    ),
    #242424;
  background-blend-mode: overlay, normal;
  box-shadow: 0px -2px 2px 0px rgba(255, 255, 255, 0.07) inset,
    0px 2px 2px 0px rgba(0, 0, 0, 0.25);

  height: 52px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 20px;

  color: white;

  transition: background-color 300ms ease;

  div {
    font-size: 16px !important;
  }
  &:disabled {
    cursor: auto;
    opacity: 0.5;
  }
`;

export const ButtonLight = styled(Base)`
  background-color: ${({ theme }) => theme.primary5};
  color: ${({ theme }) => theme.primaryText1};
  font-size: 16px;
  font-weight: 400;
  &:focus {
    box-shadow: 0 0 0 1pt
      ${({ theme, disabled }) => !disabled && darken(0.03, theme.primary5)};
    background-color: ${({ theme, disabled }) =>
      !disabled && darken(0.03, theme.primary5)};
  }
  &:hover:enabled {
    background-color: ${({ theme, disabled }) =>
      !disabled && darken(0.03, theme.primary5)};
  }
  &:active {
    box-shadow: 0 0 0 1pt
      ${({ theme, disabled }) => !disabled && darken(0.05, theme.primary5)};
    background-color: ${({ theme, disabled }) =>
      !disabled && darken(0.05, theme.primary5)};
  }
  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }
`;

export const ButtonGray = styled(Base)`
  background-color: ${({ theme }) => theme.bg3};
  color: ${({ theme }) => theme.text2};
  font-size: 16px;
  font-weight: 400;
  &:focus {
    box-shadow: 0 0 0 1pt
      ${({ theme, disabled }) => !disabled && darken(0.05, theme.bg2)};
    background-color: ${({ theme, disabled }) =>
      !disabled && darken(0.05, theme.bg2)};
  }
  &:hover {
    background-color: ${({ theme, disabled }) =>
      !disabled && darken(0.05, theme.bg2)};
  }
  &:active {
    box-shadow: 0 0 0 1pt
      ${({ theme, disabled }) => !disabled && darken(0.1, theme.bg2)};
    background-color: ${({ theme, disabled }) =>
      !disabled && darken(0.1, theme.bg2)};
  }
`;

export const ButtonSecondary = styled(Base)`
  height: 26px;

  background: linear-gradient(
      180.19deg,
      rgba(255, 255, 255, 0.286) -121.91%,
      rgba(255, 255, 255, 0.65) -111.98%,
      rgba(255, 255, 255, 0) -21.72%,
      rgba(255, 255, 255, 0) 51.78%
    ),
    linear-gradient(0deg, #222222, #222222), #d9d9d9;
  background-blend-mode: overlay, normal, normal;
  border: 1px solid #000000;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px -2px 2px rgba(255, 255, 255, 0.07);
  border-radius: 5px;

  font-size: 14px;
  line-height: 20px;
  color: #7f7f7f;
  text-transform: lowercase;
  padding: ${({ $padding }) => ($padding ? $padding : "3px 20px")};

  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.primary4};
    background-color: ${({ theme }) => theme.primary4};
  }
  &:hover {
    background-color: ${({ theme }) => theme.primary4};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.primary4};
    background-color: ${({ theme }) => theme.primary4};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.primary5};
    opacity: 50%;
    cursor: auto;
  }
`;

export const ButtonPink = styled(Base)`
  background-color: ${({ theme }) => theme.primary1};
  color: white;

  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.primary1)};
    background-color: ${({ theme }) => darken(0.05, theme.primary1)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.primary1)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.primary1)};
    background-color: ${({ theme }) => darken(0.1, theme.primary1)};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.primary1};
    opacity: 50%;
    cursor: auto;
  }
`;

export const ButtonOutlined = styled(Base)`
  border: 1px solid ${({ theme }) => theme.bg2};
  background-color: transparent;
  color: ${({ theme }) => theme.text1};

  &:focus {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.bg4};
  }
  &:hover {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.bg4};
  }
  &:active {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.bg4};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`;

export const ButtonEmpty = styled(Base)`
  background-color: transparent;
  color: ${({ theme }) => theme.primary1};
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    background-color: ${({ theme }) => theme.advancedBG};
  }
  &:hover {
    background-color: ${({ theme }) => theme.advancedBG};
  }
  &:active {
    background-color: ${({ theme }) => theme.advancedBG};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`;

export const ButtonWhite = styled(Base)`
  border: 1px solid #edeef2;
  background-color: ${({ theme }) => theme.bg1};
  color: black;

  &:focus {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    box-shadow: 0 0 0 1pt ${darken(0.05, "#edeef2")};
  }
  &:hover {
    box-shadow: 0 0 0 1pt ${darken(0.1, "#edeef2")};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${darken(0.1, "#edeef2")};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`;

const ButtonConfirmedStyle = styled(Base)`
  background-color: ${({ theme }) => lighten(0.5, theme.green1)};
  color: ${({ theme }) => theme.green1};
  border: 1px solid ${({ theme }) => theme.green1};

  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`;

const ButtonErrorStyle = styled(Base)`
  background-color: ${({ theme }) => theme.red1};
  border: 1px solid ${({ theme }) => theme.red1};

  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.red1)};
    background-color: ${({ theme }) => darken(0.05, theme.red1)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.red1)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.red1)};
    background-color: ${({ theme }) => darken(0.1, theme.red1)};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
    box-shadow: none;
    background-color: ${({ theme }) => theme.red1};
    border: 1px solid ${({ theme }) => theme.red1};
  }
`;

export function ButtonConfirmed({
  confirmed,
  altDisabledStyle,
  ...rest
}: { confirmed?: boolean; altDisabledStyle?: boolean } & ButtonProps) {
  if (confirmed) {
    return <ButtonConfirmedStyle {...rest} />;
  } else {
    return <ButtonPrimary {...rest} $altDisabledStyle={altDisabledStyle} />;
  }
}

export function ButtonError({
  error,
  ...rest
}: { error?: boolean } & ButtonProps) {
  if (error) {
    return <ButtonErrorStyle {...rest} />;
  } else {
    return <ButtonPrimary {...rest} />;
  }
}

export function ButtonDropdown({
  disabled = false,
  children,
  ...rest
}: { disabled?: boolean } & ButtonProps) {
  return (
    <ButtonPrimary {...rest} disabled={disabled}>
      <RowBetween>
        <div style={{ display: "flex", alignItems: "center" }}>{children}</div>
        <ChevronDown size={24} />
      </RowBetween>
    </ButtonPrimary>
  );
}

export function ButtonDropdownLight({
  disabled = false,
  children,
  ...rest
}: { disabled?: boolean } & ButtonProps) {
  return (
    <ButtonOutlined {...rest} disabled={disabled}>
      <RowBetween>
        <div style={{ display: "flex", alignItems: "center" }}>{children}</div>
        <ChevronDown size={24} />
      </RowBetween>
    </ButtonOutlined>
  );
}

export function ButtonRadio({
  active,
  ...rest
}: { active?: boolean } & ButtonProps) {
  if (!active) {
    return <ButtonWhite {...rest} />;
  } else {
    return <ButtonPrimary {...rest} />;
  }
}

const IconContainer = styled.div`
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-flex;
`;

export const ButtonPrimary: FC<ComponentProps<typeof StyledButtonPrimary> & {
  icon?: ReactNode;
}> = ({ icon, children, ...props }) => {
  return (
    <StyledButtonPrimary {...props}>
      {icon && <IconContainer>{icon}</IconContainer>}
      {children}
    </StyledButtonPrimary>
  );
};
