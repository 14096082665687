import React from "react";
import { ConnectButton as RKConnectButton } from "@rainbow-me/rainbowkit";
import { LedLight } from "../ConnectWalletApplet/Footer";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { ReactComponent as WalletIcon } from "../../assets/svg/wallet.svg";
import { Button as RebassButton } from "rebass";

const HideMobile = styled.div`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`;

export const ConnectButton = ({
  buttonComponent: ButtonComponent,
  isSmall = true,
}: {
  buttonComponent?: typeof RebassButton;
  isSmall?: boolean;
}) => {
  const DefaultButton = ({
    children,
    ...props
  }: {
    children?: React.ReactNode;
  }) => <button {...props}>{children}</button>;

  const Button = ButtonComponent ?? DefaultButton;

  return (
    <RKConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== "loading";
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === "authenticated");

        return (
          <div
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <Button onClick={openConnectModal} type="button">
                    {!isMobile || !isSmall ? <>connect</> : <WalletIcon />}
                  </Button>
                );
              }

              if (chain.unsupported) {
                return (
                  <Button onClick={openChainModal} type="button">
                    wrong network
                  </Button>
                );
              }

              return (
                <div style={{ display: "flex", gap: 12 }}>
                  <HideMobile>
                    <Button
                      onClick={openChainModal}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        textTransform: "lowercase",
                      }}
                      type="button"
                    >
                      {chain.hasIcon && chain.iconUrl && (
                        <img
                          alt={chain.name ?? "Chain icon"}
                          src={chain.iconUrl}
                          style={{ width: 12, height: 12 }}
                        />
                      )}
                      {chain.name}
                    </Button>
                  </HideMobile>

                  <Button onClick={openAccountModal} type="button">
                    {!isMobile ? (
                      <>
                        <LedLight />
                        {account.ensName ?? account.displayName}
                      </>
                    ) : (
                      <WalletIcon
                        style={{
                          filter:
                            "drop-shadow(0px 0px 4px #00FF38) drop-shadow(0px 0px 1px #05CE31)",
                        }}
                      />
                    )}
                  </Button>
                </div>
              );
            })()}
          </div>
        );
      }}
    </RKConnectButton.Custom>
  );
};
