import { TYPE } from "../../theme";
import CurrencyLogo from "../CurrencyLogo";
import { Trade } from "@beamswap/sdk";
import { Fragment, memo, useContext } from "react";
import { ChevronRight } from "react-feather";
import { Flex } from "rebass";
import { ThemeContext } from "styled-components";
import { useAllTokens } from "../../hooks/Tokens";
import { isDefined } from "../../utils/typePresence";

export default memo(function SwapRoute({ trade }: { trade: Trade }) {
  const theme = useContext(ThemeContext);
  const allTokens = useAllTokens();

  const tradePaths = trade.route.path
    .map((token) => allTokens[token.address])
    .filter(isDefined);

  return (
    <Flex
      px="1rem"
      py="0.5rem"
      my="0.5rem"
      style={{ border: `1px solid ${theme?.bg3}`, borderRadius: "1rem" }}
      flexWrap="wrap"
      width="100%"
      justifyContent="space-evenly"
      alignItems="center"
    >
      {tradePaths.map((token, i, path) => {
        const isLastItem: boolean = i === path.length - 1;
        return (
          <Fragment key={i}>
            <Flex my="0.5rem" alignItems="center" style={{ flexShrink: 0 }}>
              <CurrencyLogo currency={token} size="1.5rem" />
              <TYPE.Black
                fontSize={14}
                color={theme?.text1}
                ml="0.5rem"
                style={{ textTransform: "uppercase" }}
              >
                {token.symbol}
              </TYPE.Black>
            </Flex>
            {isLastItem ? null : <ChevronRight color={theme?.text2} />}
          </Fragment>
        );
      })}
    </Flex>
  );
});
