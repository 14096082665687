import { transparentize } from "polished";
import React from "react";
import styled, { css } from "styled-components";
import * as RadixPopover from "@radix-ui/react-popover";

const PopoverContainer = styled(RadixPopover.Content)<{ $show: boolean }>`
  z-index: 9999;
  transition: visibility 150ms linear, opacity 150ms linear;
  background: #000;
  border: 1px solid ${({ theme }) => theme.bg3};
  box-shadow: 0 4px 8px 0 ${({ theme }) => transparentize(0.9, theme.shadow1)};
  color: ${({ theme }) => theme.text2};
  border-radius: 8px;
  pointer-events: none !important;

  ${({ $show }) =>
    $show
      ? css`
          visibility: visible;
          opacity: 1;
        `
      : css`
          visibility: hidden;
          opacity: 0;
        `}
`;

const Trigger = styled.div`
  display: inline-block;
`;

export interface PopoverProps {
  content: React.ReactNode;
  show: boolean;
  children: React.ReactNode;
  placement?: "auto" | "top" | "right" | "bottom" | "left";
}

export default function Popover({
  content,
  show,
  children,
  placement = "auto",
}: PopoverProps) {
  return (
    <RadixPopover.Root open>
      <RadixPopover.Trigger asChild>
        <Trigger>{children}</Trigger>
      </RadixPopover.Trigger>
      <RadixPopover.Portal>
        <PopoverContainer
          $show={show}
          sideOffset={8}
          side={placement === "auto" ? undefined : placement}
        >
          {content}
        </PopoverContainer>
      </RadixPopover.Portal>
    </RadixPopover.Root>
  );
}
