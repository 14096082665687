import { useAccount } from "wagmi";
import { ButtonPrimary } from "../../components/Button";
import { LightCard } from "../../components/Card";
import { AutoColumn } from "../../components/Column";
import FullPositionCard from "../../components/PositionCard";
import Question from "../../components/QuestionHelper";
import { RowBetween } from "../../components/Row";
import { Dots } from "../../components/swap/styleds";
import { usePairs } from "../../data/Reserves";
import {
  toV2LiquidityToken,
  useTrackedTokenPairs,
} from "../../state/user/hooks";
import { useTokenBalancesWithLoadingIndicator } from "../../state/wallet/hooks";
import { StyledInternalLink, TYPE } from "../../theme";
import AppBody from "../AppBody";
import { Pair } from "@beamswap/sdk";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Text } from "rebass";
import { ThemeContext } from "styled-components";

export default function Pool() {
  const theme = useContext(ThemeContext);
  const { t } = useTranslation();
  const { address } = useAccount();

  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs();
  const tokenPairsWithLiquidityTokens = useMemo(
    () =>
      trackedTokenPairs.map((tokens) => ({
        liquidityToken: toV2LiquidityToken(tokens),
        tokens,
      })),
    [trackedTokenPairs]
  );
  const liquidityTokens = useMemo(
    () => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken),
    [tokenPairsWithLiquidityTokens]
  );
  const [
    v2PairsBalances,
    fetchingV2PairBalances,
  ] = useTokenBalancesWithLoadingIndicator(
    address ?? undefined,
    liquidityTokens
  );

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan("0")
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  );

  const v2Pairs = usePairs(
    liquidityTokensWithBalances.map(({ tokens }) => tokens)
  );
  const v2IsLoading =
    fetchingV2PairBalances ||
    v2Pairs?.length < liquidityTokensWithBalances.length ||
    v2Pairs?.some((V2Pair) => !V2Pair);

  const allV2PairsWithLiquidity = v2Pairs
    .map(([, pair]) => pair)
    .filter((v2Pair): v2Pair is Pair => Boolean(v2Pair));

  return (
    <>
      <AppBody>
        <AutoColumn $gap="lg" $justify="center">
          <ButtonPrimary
            id="join-pool-button"
            as={Link}
            style={{ padding: 16 }}
            // @ts-ignore - styled-components typing error
            to="/add/ETH"
          >
            <Text fontWeight={400} fontSize={20}>
              {t("addLiquidity")}
            </Text>
          </ButtonPrimary>

          <AutoColumn $gap="12px" style={{ width: "100%" }}>
            <RowBetween $padding={"0 8px"}>
              <Text color={theme?.text1} fontWeight={400}>
                {t("yourLiquidity")}
              </Text>
              <Question text="When you add liquidity, you are given pool tokens that represent your share. If you don’t see a pool you joined in this list, try importing a pool below." />
            </RowBetween>

            {!address ? (
              <LightCard $padding="40px">
                <TYPE.Body color={theme?.text3} textAlign="center">
                  {t("connectToViewLiquidity")}
                </TYPE.Body>
              </LightCard>
            ) : v2IsLoading ? (
              <LightCard $padding="40px">
                <TYPE.Body color={theme?.text3} textAlign="center">
                  <Dots>{t("loading")}</Dots>
                </TYPE.Body>
              </LightCard>
            ) : allV2PairsWithLiquidity?.length > 0 ? (
              <>
                {allV2PairsWithLiquidity.map((v2Pair) => (
                  <FullPositionCard
                    key={v2Pair.liquidityToken.address}
                    pair={v2Pair}
                  />
                ))}
              </>
            ) : (
              <LightCard $padding="40px">
                <TYPE.Body color={theme?.text3} textAlign="center">
                  {t("noLiquidityFound")}
                </TYPE.Body>
              </LightCard>
            )}

            <div>
              <Text
                textAlign="center"
                fontSize={14}
                style={{ padding: ".5rem 0 .5rem 0" }}
              >
                {"Don't see a pool you joined?"}{" "}
                <StyledInternalLink id="import-pool-link" to={"/find"}>
                  {"Import it."}
                </StyledInternalLink>
              </Text>
            </div>
          </AutoColumn>
        </AutoColumn>
      </AppBody>
    </>
  );
}
