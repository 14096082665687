import Popover, { PopoverProps } from "../Popover";
import { useCallback, useState } from "react";
import styled from "styled-components";

const TooltipContainer = styled.div`
  width: 228px;
  padding: 0.3rem 0.5rem;
  line-height: 1.2em;
  font-weight: 400;

  font-size: 12px;
  color: #f1f1f5;
  letter-spacing: 0;
`;

interface TooltipProps extends Omit<PopoverProps, "content"> {
  text: string;
}

export default function Tooltip({ text, ...rest }: TooltipProps) {
  return (
    <Popover content={<TooltipContainer>{text}</TooltipContainer>} {...rest} />
  );
}

export function MouseoverTooltip({
  children,
  ...rest
}: Omit<TooltipProps, "show">) {
  const [show, setShow] = useState(false);
  const open = useCallback(() => setShow(true), [setShow]);
  const close = useCallback(() => setShow(false), [setShow]);
  return (
    <Tooltip {...rest} show={show}>
      <div onMouseEnter={open} onMouseLeave={close}>
        {children}
      </div>
    </Tooltip>
  );
}
