import LcdPng from "../../../assets/images/lcd.png";
import React from "react";
import styled, { keyframes } from "styled-components";

export const Marquee = styled.div`
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;

  background-color: #04040b;
  position: relative;
  border: 1px solid #131313;
  border-radius: 10px;

  span {
    float: left;
    width: 50%;
    overflow: hidden;
  }
`;

export const Background = styled.div`
  background-image: url(${LcdPng});
  background-repeat: repeat;
  background-size: 1%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    opacity: 0.1;
  `};
`;

export const MarqueeAnimation = keyframes`
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
`;

export const MarqueeInner = styled.div`
  display: block;
  width: 200%;
  position: absolute;
  animation: ${MarqueeAnimation} 10s linear infinite;
  &:hover {
    animation-play-state: paused;
    color: red;
  }
`;

export const NeonText = styled.span`
  font-family: "SuisseIntl";
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: #ebbcff;

  text-shadow: 0px 0px 10px rgba(216, 162, 239, 0.5);
`;

export const LcdMarquee = () => {
  return (
    <div>
      <Marquee>
        <Background />
        <MarqueeInner>
          <NeonText>
            <div>
              connect wallet to unlock. connect wallet to unlock. connect wallet
              to unlock. connect wallet to unlock.&nbsp;
            </div>
          </NeonText>
          <NeonText>
            <div>
              connect wallet to unlock. connect wallet to unlock. connect wallet
              to unlock. connect wallet to unlock.&nbsp;
            </div>
          </NeonText>
        </MarqueeInner>
      </Marquee>
    </div>
  );
};
