import Tooltip from "../Tooltip";
import { useCallback, useState } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as QuestionIcon } from "../../assets/svg/question_icon.svg";

const QuestionWrapper = styled.div<{ $isSmall: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  outline: none;
  cursor: default;
  border-radius: 36px;
  color: ${({ theme }) => theme.text2};

  ${({ $isSmall }) =>
    $isSmall &&
    css`
      width: 12px;
      height: 12px;
    `};

  :hover,
  :focus {
    opacity: 0.7;
  }
`;

const Container = styled.span<{ $isSmall: boolean }>`
  margin-left: 4px;
  display: inline-flex;

  ${({ $isSmall }) =>
    $isSmall &&
    css`
      padding: 0.4rem;
    `};
`;

export default function QuestionHelper({
  text,
  isSmall,
}: {
  text: string;
  isSmall?: boolean;
}) {
  const [show, setShow] = useState<boolean>(false);

  const open = useCallback(() => setShow(true), [setShow]);
  const close = useCallback(() => setShow(false), [setShow]);

  return (
    <Container $isSmall={!!isSmall}>
      <Tooltip text={text} show={show}>
        <QuestionWrapper
          $isSmall={!!isSmall}
          onClick={open}
          onMouseEnter={open}
          onMouseLeave={close}
        >
          <QuestionIcon />
        </QuestionWrapper>
      </Tooltip>
    </Container>
  );
}
