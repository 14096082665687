import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import {
  useSettingsMenuOpen,
  useToggleSettingsMenu,
} from "../../state/application/hooks";
import {
  useUserSlippageTolerance,
  useExpertModeManager,
  useUserDeadline,
} from "../../state/user/hooks";
import { TYPE } from "../../theme";
import { Applet } from "../Applet";
import { ButtonPrimary } from "../Button";
import { AutoColumn } from "../Column";
import Modal from "../Modal";
import { Pill } from "../Pill";
import QuestionHelper from "../QuestionHelper";
import { RowFixed, RowBetween } from "../Row";
import Toggle from "../Toggle";
import TransactionSettings from "../TransactionSettings";
import { useRef, useContext, useState } from "react";
import { X } from "react-feather";
import { useTranslation } from "react-i18next";
import { Text } from "rebass";
import styled from "styled-components";
import { ThemeContext } from "styled-components";
import { ReactComponent as SettingsIcon } from "../../assets/svg/settings.svg";

const StyledCloseIcon = styled(X)`
  height: 20px;
  width: 20px;
  :hover {
    cursor: pointer;
  }

  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`;

const StyledMenuButton = styled(Pill).attrs({ $isSelected: true })`
  position: relative;
  width: fit-content;
  margin: 0;
  padding: 0;

  padding-left: 16px;
  padding-right: 16px;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
  }

  svg {
    margin-top: 2px;
    display: none;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    span {
      display: none;
    }

    svg {
      display: block
    }
  `};
`;
const EmojiWrapper = styled.div`
  position: absolute;
  bottom: -6px;
  right: 0px;
  font-size: 14px;
`;

const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;

  ${({ theme }) => theme.mediaWidth.upToSmall`
   margin-left: 0;
  `};
`;

const ModalContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
`;

const MenuFlyout = styled(Applet)`
  min-width: 20.125rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 3rem;
  right: 0rem;
  z-index: 100;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    min-width: 18.125rem;
    left: 0;
    right: 0;
    position: fixed;
    top: 4rem;

    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.1);
  `};

  padding-top: 0;
  padding-bottom: 0;
  padding-left: 8px;
  padding-right: 8px;
`;

const ErrorMessage = styled.div`
  padding: 1em;
  border: 1px solid ${({ theme }) => theme.red2};
  border-radius: 10px;
`;

export default function SettingsTab() {
  const node = useRef<HTMLDivElement>();
  const open = useSettingsMenuOpen();
  const toggle = useToggleSettingsMenu();
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  const [
    userSlippageTolerance,
    setUserslippageTolerance,
  ] = useUserSlippageTolerance();

  const [deadline, setDeadline] = useUserDeadline();

  const [expertMode, toggleExpertMode] = useExpertModeManager();

  //const [darkMode, toggleDarkMode] = useDarkModeManager()

  // show confirmation view before turning on
  const [showConfirmation, setShowConfirmation] = useState(false);

  useOnClickOutside(node, open ? toggle : undefined);

  return (
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
    <StyledMenu ref={node as any}>
      <Modal
        isOpen={showConfirmation}
        onDismiss={() => setShowConfirmation(false)}
        maxHeight={100}
      >
        <ModalContentWrapper>
          <AutoColumn $gap="lg">
            <RowBetween>
              <Text fontWeight={400} fontSize={16}>
                {t("areYouSure")}
              </Text>
              <StyledCloseIcon onClick={() => setShowConfirmation(false)} />
            </RowBetween>
            <AutoColumn $gap="lg">
              <ErrorMessage>
                <Text fontWeight={400} fontSize={16} color={theme?.red2}>
                  {t("expertModeTips1")}
                </Text>
                <Text fontWeight={400} fontSize={16} color={theme?.red2}>
                  {t("expertModeTips2")}
                </Text>
              </ErrorMessage>
              <ButtonPrimary
                onClick={() => {
                  if (
                    window.prompt(
                      `Please type the word "confirm" to enable expert mode.`
                    ) === "confirm"
                  ) {
                    toggleExpertMode();
                    setShowConfirmation(false);
                  }
                }}
              >
                <Text fontSize={20} fontWeight={400} id="confirm-expert-mode">
                  {t("turnOnExpertMode")}
                </Text>
              </ButtonPrimary>
            </AutoColumn>
          </AutoColumn>
        </ModalContentWrapper>
      </Modal>
      <StyledMenuButton onClick={toggle} id="open-settings-dialog-button">
        <span>settings</span>

        <SettingsIcon />
        {expertMode && (
          <EmojiWrapper>
            <span role="img" aria-label="wizard-icon">
              🧙
            </span>
          </EmojiWrapper>
        )}
      </StyledMenuButton>
      {open && (
        <MenuFlyout>
          <AutoColumn $gap="md" style={{ padding: "1rem" }}>
            <Text fontWeight={400} fontSize={14}>
              {t("transactionSettings")}
            </Text>
            <TransactionSettings
              rawSlippage={userSlippageTolerance}
              setRawSlippage={setUserslippageTolerance}
              deadline={deadline}
              setDeadline={setDeadline}
            />
            <Text fontWeight={400} fontSize={14}>
              {t("interfaceSettings")}
            </Text>
            <RowBetween>
              <RowFixed>
                <TYPE.Black fontWeight={400} fontSize={12} color={theme?.text2}>
                  {t("toggleExpertMode")}
                </TYPE.Black>
                <QuestionHelper
                  isSmall
                  text="Bypasses confirmation modals and allows high slippage trades. Use at your own risk."
                />
              </RowFixed>
              <Toggle
                id="toggle-expert-mode-button"
                isActive={expertMode}
                toggle={
                  expertMode
                    ? () => {
                        toggleExpertMode();
                        setShowConfirmation(false);
                      }
                    : () => {
                        toggle();
                        setShowConfirmation(true);
                      }
                }
              />
            </RowBetween>
          </AutoColumn>
        </MenuFlyout>
      )}
    </StyledMenu>
  );
}
