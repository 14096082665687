import styled from "styled-components";

const Thumb = styled.button.attrs<{
  $isActive?: boolean;
}>(() => ({
  role: "checkbox",
  tabIndex: 0,
}))`
  width: 100%;
  height: 14px;
  position: absolute;
  top: ${({ $isActive }) => ($isActive ? "11px" : "-1px")};
  border-radius: 6px;
  border: 1px solid black;
  cursor: pointer;
  background: linear-gradient(
      178deg,
      rgba(255, 255, 255, 0.11) 1.34%,
      rgba(255, 255, 255, 0.25) 5.7%,
      rgba(255, 255, 255, 0) 45.31%,
      rgba(255, 255, 255, 0) 77.57%
    ),
    #242424;
  background-blend-mode: overlay, normal;
  box-shadow: 0px -2px 2px 0px rgba(255, 255, 255, 0.07) inset,
    0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  transition: top 200ms ease-in-out;
`;

const Track = styled.label<{
  $isActive?: boolean;
}>`
  position: relative;
  border-radius: 7px;
  border: 1px solid black;
  display: flex;
  cursor: pointer;
  width: 62px;
  height: 26px;
  transition: background-color 200ms ease-in-out;
  background-color: ${({ $isActive }) => ($isActive ? "#48dd13" : "#ff5c00")};
`;

const InvisibleCheckbox = styled.input`
  opacity: 0;
  pointer-events: none;
`;

export interface ToggleProps {
  id?: string;
  isActive: boolean;
  toggle: () => void;
}

export default function Toggle({ id, isActive, toggle }: ToggleProps) {
  return (
    <Track htmlFor={id} $isActive={isActive}>
      <InvisibleCheckbox
        id={id}
        type="checkbox"
        aria-hidden
        checked={isActive}
        onChange={() => toggle()}
        tabIndex={-1}
      />
      <Thumb $isActive={isActive} aria-checked={isActive} onClick={toggle} />
    </Track>
  );
}
