import React from "react";
import styled from "styled-components";
import { Applet } from "../components/Applet";
import { useAccount } from "wagmi";
import { ConnectWalletApplet } from "../components/ConnectWalletApplet";

export const BodyWrapper = styled(Applet)`
  position: relative;
  max-width: 550px;
  width: 100%;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 24px 32px rgba(0, 0, 0, 0.01);
  padding: 35px 39px;

  @media screen and (max-width: 768px) {
    padding: 15px 11px;
  }
`;

const HelpLink = styled.a`
  display: block;
  width: 100%;
  font-size: 12px;
  margin-top: auto;
  padding: 1rem;
  color: #f2f2f2;

  &:hover {
    text-decoration: underline;
  }
`;

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  const { address } = useAccount();

  return (
    <>
      <BodyWrapper>{!address ? <ConnectWalletApplet /> : children}</BodyWrapper>
      <HelpLink
        href="https://support.onbeam.com/hc/en-us/categories/14553016517533-Beam-Swap"
        target="_blank"
        rel="noreferrer noopener"
      >
        need help? get support
      </HelpLink>
    </>
  );
}
