import { TYPE } from "../../theme";
import { Lcd } from "../Applet";
import { AutoColumn } from "../Column";
import { Pill } from "../Pill";
import QuestionHelper from "../QuestionHelper";
import { RowBetween, RowFixed } from "../Row";
import { useState, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import styled, { ThemeContext } from "styled-components";

enum SlippageError {
  InvalidInput = "InvalidInput",
  RiskyLow = "RiskyLow",
  RiskyHigh = "RiskyHigh",
}

enum DeadlineError {
  InvalidInput = "InvalidInput",
}

const Option = styled(Pill)`
  margin-right: 8px;

  :hover {
    cursor: pointer;
  }
`;

const Input = styled.input`
  font-size: 16px;
  outline: none;
  height: 100%;
  border: 0px;
  border-radius: 2rem;
  background-color: transparent;
  color: ${({ theme, color }) => (color === "red" ? theme.red1 : theme.text1)};
  display: flex;
  flex-grow: 1;
  padding-right: 0.5em;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

const OptionCustom = styled.div`
  height: 2rem;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 10px;
`;

const StyledLcd = styled(Lcd)`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const SlippageEmojiContainer = styled.span`
  color: #f3841e;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;  
  `}
`;

export interface SlippageTabsProps {
  rawSlippage: number;
  setRawSlippage: (rawSlippage: number) => void;
  deadline: number;
  setDeadline: (deadline: number) => void;
}

export default function SlippageTabs({
  rawSlippage,
  setRawSlippage,
  deadline,
  setDeadline,
}: SlippageTabsProps) {
  const theme = useContext(ThemeContext);
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>();

  const [slippageInput, setSlippageInput] = useState("");
  const [deadlineInput, setDeadlineInput] = useState("");

  const slippageInputIsValid =
    slippageInput === "" ||
    (rawSlippage / 100).toFixed(2) ===
      Number.parseFloat(slippageInput).toFixed(2);
  const deadlineInputIsValid =
    deadlineInput === "" || (deadline / 60).toString() === deadlineInput;

  let slippageError: SlippageError | undefined;
  if (slippageInput !== "" && !slippageInputIsValid) {
    slippageError = SlippageError.InvalidInput;
  } else if (slippageInputIsValid && rawSlippage < 50) {
    slippageError = SlippageError.RiskyLow;
  } else if (slippageInputIsValid && rawSlippage > 500) {
    slippageError = SlippageError.RiskyHigh;
  } else {
    slippageError = undefined;
  }

  let deadlineError: DeadlineError | undefined;
  if (deadlineInput !== "" && !deadlineInputIsValid) {
    deadlineError = DeadlineError.InvalidInput;
  } else {
    deadlineError = undefined;
  }

  function parseCustomSlippage(value: string) {
    setSlippageInput(value);

    try {
      const valueAsIntFromRoundedFloat = Number.parseInt(
        (Number.parseFloat(value) * 100).toString()
      );
      if (
        !Number.isNaN(valueAsIntFromRoundedFloat) &&
        valueAsIntFromRoundedFloat < 5000
      ) {
        setRawSlippage(valueAsIntFromRoundedFloat);
      }
    } catch {}
  }

  function parseCustomDeadline(value: string) {
    setDeadlineInput(value);

    try {
      const valueAsInt: number = Number.parseInt(value) * 60;
      if (!Number.isNaN(valueAsInt) && valueAsInt > 0) {
        setDeadline(valueAsInt);
      }
    } catch {}
  }

  return (
    <AutoColumn $gap="md">
      <AutoColumn $gap="sm">
        <RowFixed>
          <TYPE.Black fontWeight={400} fontSize={12} color={theme?.text2}>
            {t("slippageTolerance")}
          </TYPE.Black>
          <QuestionHelper
            isSmall
            text="Your transaction will revert if the price changes unfavorably by more than this percentage."
          />
        </RowFixed>
        <RowBetween>
          <StyledLcd>
            <OptionCustom
              // $active={![10, 50, 100].includes(rawSlippage)}
              // $warning={!slippageInputIsValid}
              tabIndex={-1}
            >
              {!!slippageInput &&
              (slippageError === SlippageError.RiskyLow ||
                slippageError === SlippageError.RiskyHigh) ? (
                <SlippageEmojiContainer>
                  <span role="img" aria-label="warning">
                    ⚠️
                  </span>
                </SlippageEmojiContainer>
              ) : null}
              {/* https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451 */}
              <Input
                ref={inputRef as any}
                placeholder={(rawSlippage / 100).toFixed(2)}
                value={slippageInput}
                onBlur={() => {
                  parseCustomSlippage((rawSlippage / 100).toFixed(2));
                }}
                onChange={(e) => parseCustomSlippage(e.target.value)}
                color={!slippageInputIsValid ? "red" : ""}
              />
              <TYPE.Body fontSize={12}>%</TYPE.Body>
            </OptionCustom>
          </StyledLcd>
        </RowBetween>
        {!!slippageError && (
          <RowBetween
            style={{
              fontSize: "12px",
              color:
                slippageError === SlippageError.InvalidInput
                  ? theme?.red2
                  : "#F3841E",
            }}
          >
            {slippageError === SlippageError.InvalidInput
              ? "Enter a valid slippage percentage"
              : slippageError === SlippageError.RiskyLow
              ? "Your transaction may fail"
              : "Your transaction may be frontrun"}
          </RowBetween>
        )}
        <RowBetween>
          <Option
            onClick={() => {
              setSlippageInput("");
              setRawSlippage(10);
            }}
            $isSelected={rawSlippage === 10}
          >
            0.1%
          </Option>
          <Option
            onClick={() => {
              setSlippageInput("");
              setRawSlippage(50);
            }}
            $isSelected={rawSlippage === 50}
          >
            0.5%
          </Option>
          <Option
            onClick={() => {
              setSlippageInput("");
              setRawSlippage(100);
            }}
            $isSelected={rawSlippage === 100}
          >
            1%
          </Option>
        </RowBetween>
      </AutoColumn>

      <AutoColumn $gap="sm">
        <RowFixed>
          <TYPE.Black fontSize={12} fontWeight={400} color={theme?.text2}>
            {t("transactionDeadline")}
          </TYPE.Black>
          <QuestionHelper
            isSmall
            text="Your transaction will revert if it is pending for more than this long."
          />
        </RowFixed>
        <RowBetween>
          <StyledLcd>
            <OptionCustom tabIndex={-1}>
              <Input
                color={!!deadlineError ? "red" : undefined}
                onBlur={() => {
                  parseCustomDeadline((deadline / 60).toString());
                }}
                placeholder={(deadline / 60).toString()}
                value={deadlineInput}
                onChange={(e) => parseCustomDeadline(e.target.value)}
              />
              <TYPE.Body fontSize={12}>{t("minutes")}</TYPE.Body>
            </OptionCustom>
          </StyledLcd>
        </RowBetween>
      </AutoColumn>
    </AutoColumn>
  );
}
