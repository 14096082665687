import React from "react";
import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  bottom: 0;
  right: 0;
  filter: blur(200px);
`;

export const GradientWrapper = styled.div`
  position: absolute;
  bottom: -100%;
`;

export const GradientBackground = styled.div<{ $gradient: string }>`
  background: ${({ $gradient }) => $gradient};
  height: 215px;
  width: 150vw;
`;

const GRADIENTS = [
  {
    id: 1,
    gradient:
      "linear-gradient(180.03deg, #BBFFCA 52.5%, #48DD13 89.08%, #008805 119.28%)",
  },
  {
    id: 2,
    gradient:
      "linear-gradient(180.03deg, #F1E869 52.5%, #FEA514 89.08%, #FF4539 119.28%)",
  },
  {
    id: 3,
    gradient:
      "linear-gradient(180.03deg, #FF6A9A 52.5%, #FF5544 89.08%, #FF4539 119.28%)",
  },
  {
    id: 4,
    gradient:
      "linear-gradient(180.03deg, #BBDBFF 52.5%, #139EDD 89.08%, #B9F7EA 119.28%)",
  },
].reverse();

export const Background = () => {
  return (
    <Container>
      <GradientWrapper>
        {GRADIENTS.map(({ id, gradient }) => (
          <GradientBackground key={id} $gradient={gradient} />
        ))}
      </GradientWrapper>
    </Container>
  );
};
