import { useMemo } from "react";
import { SUGGESTED_BASES } from "../../constants";
import { useAllTokens } from "../../hooks/Tokens";
import { Lcd } from "../Applet";
import { AutoColumn } from "../Column";
import CurrencyLogo from "../CurrencyLogo";
import { Pill } from "../Pill";
import { AutoRow } from "../Row";
import { ChainId, Currency, currencyEquals, DEV, Token } from "@beamswap/sdk";
import { Text } from "rebass";
import styled, { css } from "styled-components";
import { isDefined } from "../../utils/typePresence";

const StyledPill = styled(Pill)<{ $disabled?: boolean }>`
  border: 1px solid #131313;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.11) 0%,
      rgba(255, 255, 255, 0.25) 4.48%,
      rgba(255, 255, 255, 0) 45.18%,
      rgba(255, 255, 255, 0) 78.33%
    ),
    #242424;
  background-blend-mode: overlay, normal;
  box-shadow: 0px -2px 2px 0px rgba(255, 255, 255, 0.07) inset,
    0px 2px 2px 0px rgba(0, 0, 0, 0.25);

  display: flex;
  gap: 4px;
  height: 36px;
  justify-content: flex-start;

  width: unset;
  padding-right: 10px;

  ${({ $disabled: disabled }) =>
    disabled &&
    css`
      /* background: linear-gradient(
          178deg,
          rgba(255, 255, 255, 0.11) 1.34%,
          rgba(255, 255, 255, 0.25) 5.7%,
          rgba(255, 255, 255, 0) 45.31%,
          rgba(255, 255, 255, 0) 77.57%
        ),
        #131313;
      background-blend-mode: overlay, normal;
      box-shadow: 0px -2px 2px 0px rgba(255, 255, 255, 0.07) inset,
        0px 2px 2px 0px rgba(0, 0, 0, 0.25);
      color: #6d6d6d; */
      opacity: 0.4;
    `}
`;

export default function CommonBases({
  chainId,
  onSelect,
  selectedCurrency,
}: {
  chainId?: ChainId;
  selectedCurrency?: Currency | null;
  onSelect: (currency: Currency) => void;
}) {
  const allTokens = useAllTokens();

  const suggestedBases = useMemo(
    () =>
      (chainId ? SUGGESTED_BASES[chainId] ?? [] : [])
        .map((base) => allTokens[base.address])
        .filter(isDefined),
    [allTokens, chainId]
  );

  return (
    <AutoColumn $gap="6px" style={{ marginTop: "8px" }}>
      <AutoRow>
        <Text fontSize={10} color="#6D6D6D">
          Common bases
        </Text>
        {/* <QuestionHelper text="These tokens are commonly paired with other tokens." /> */}
      </AutoRow>
      <Lcd style={{ padding: "12px" }}>
        <AutoRow gap="4px">
          <StyledPill
            onClick={() => {
              if (!selectedCurrency || !currencyEquals(selectedCurrency, DEV)) {
                onSelect(DEV);
              }
            }}
            $disabled={selectedCurrency === DEV}
          >
            <CurrencyLogo currency={DEV} size={"22px"} />

            <Text fontWeight={400} fontSize={20} color="#fff">
              BEAM
            </Text>
          </StyledPill>
          {suggestedBases.map((token: Token) => {
            const selected =
              selectedCurrency instanceof Token &&
              selectedCurrency.address === token.address;
            return (
              <StyledPill
                onClick={() => !selected && onSelect(token)}
                $disabled={selected}
                key={token.address}
              >
                <CurrencyLogo currency={token} size={"22px"} />

                <Text fontWeight={400} fontSize={20} color="#fff">
                  {token.symbol}
                </Text>
              </StyledPill>
            );
          })}
        </AutoRow>
      </Lcd>
    </AutoColumn>
  );
}
