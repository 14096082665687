import { ChainId } from "@beamswap/sdk";
import { Chain } from "wagmi";

type ChainWithIcon = Chain & { iconUrl: string };
export const CHAIN_INFO = {
  [ChainId.MAINNET]: {
    explorer: "https://etherscan.io/",
    label: "Ethereum",
    nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
    // rpcUrl: CurrentConfig.rpc.mainnet,
  },
  [ChainId.BEAM_TESTNET]: {
    explorer: ["https://subnets-test.avax.network/beam/"],
    label: "Beam Testnet",
    nativeCurrency: { name: "Wrapped Beam", symbol: "WBEAM", decimals: 18 },
    rpcUrl: ["https://subnets.avax.network/beam/testnet/rpc"],
  },
  [ChainId.BEAM]: {
    explorer: ["https://subnets.avax.network/beam/"],
    label: "Beam",
    nativeCurrency: { name: "Wrapped Beam", symbol: "WBEAM", decimals: 18 },
    rpcUrl: ["https://subnets.avax.network/beam/mainnet/rpc"],
  },
};

export const NETWORKS: Record<number, ChainWithIcon> = {
  13337: {
    id: 13_337,
    name: "Beam Testnet",
    network: "Beam Testnet",
    iconUrl: "/static/media/ethereum-logo.01952d44e520ec146674.png",
    nativeCurrency: {
      decimals: 18,
      name: "Beam",
      symbol: "BEAM",
    },
    rpcUrls: {
      public: {
        http: [
          "https://build.onbeam.com/rpc/testnet",
        ],
      },
      default: {
        http: [
          "https://eu.build.onbeam.com/rpc/testnet/mer-f10875fc-a668-3be6-9be1-6c8a7a91090c",
        ],
      },
    },
    blockExplorers: {
      default: {
        name: "Explorer",
        url: "https://subnets-test.avax.network/beam",
      },
    },
    contracts: {
      multicall3: {
        address: "0x9BF49b704EE2A095b95c1f2D4EB9010510c41C9E",
        blockCreated: 3,
      },
    },
    testnet: true,
  },
  4337: {
    id: 4337,
    name: "Beam",
    network: "Beam",
    iconUrl: "/static/media/ethereum-logo.01952d44e520ec146674.png",
    nativeCurrency: {
      decimals: 18,
      name: "Beam",
      symbol: "BEAM",
    },
    rpcUrls: {
      public: {
        http: ["https://build.onbeam.com/rpc"],
      },
      default: {
        http: ["https://eu.build.onbeam.com/rpc/mer-f10875fc-a668-3be6-9be1-6c8a7a91090c"],
      },
    },
    blockExplorers: {
      default: {
        name: "Explorer",
        url: "https://subnets.avax.network/beam",
      },
    },
    contracts: {
      multicall3: {
        address: "0x4956f15efdc3dc16645e90cc356eafa65ffc65ec",
        blockCreated: 3,
      },
    },
  },
};

export const NETWORK_ID = 4337;
