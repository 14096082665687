import { useAccount } from "wagmi";
import { useCurrencyBalance } from "../../state/wallet/hooks";
import { TYPE } from "../../theme";
import { Lcd } from "../Applet";
import CurrencySelector from "../CurrencySelector";
import NumericalInput from "../NumericalInput";
import Row from "../Row";
import CurrencySearchModal from "../SearchModal/CurrencySearchModal";
import { Currency, Pair } from "@beamswap/sdk";
import { darken } from "polished";
import { useState, useContext, useCallback } from "react";
import styled, { ThemeContext } from "styled-components";

const InputRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  align-items: center;
  margin-bottom: 12px;
`;

const Container = styled(Lcd)`
  padding: 10px;
`;

const LabelRow = styled.div`
  display: flex;
  color: ${({ theme }) => theme.text1};
  font-size: 0.75rem;
  line-height: 1rem;
  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.text2)};
  }
`;

const InputPanel = styled.div<{ $hideInput?: boolean }>`
  ${({ theme }) => theme.flexColumnNoWrap}
  position: relative;
  border-radius: ${({ $hideInput }) => ($hideInput ? "8px" : "20px")};
  background-color: ${({ theme }) => theme.bg2};
  z-index: 1;
`;

const StyledBalanceMax = styled.button`
  height: 28px;
  font-size: 12px;

  color: rgba(241, 241, 241, 0.5);
  border-radius: 35px;
  border: 1px solid rgba(241, 241, 241, 0.5);
  box-shadow: 0px -2px 2px 0px rgba(255, 255, 255, 0.07) inset;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  line-height: 1em;

  font-weight: 400;
  cursor: pointer;
  margin-left: 12px;
`;

const StyledNumericalInput = styled(NumericalInput)`
  z-index: 1;
  background: none;
`;

interface CurrencyInputPanelProps {
  value: string;
  onUserInput: (value: string) => void;
  onMax?: () => void;
  label?: string;
  onCurrencySelect?: (currency: Currency) => void;
  currency?: Currency | null;
  disableCurrencySelect?: boolean;
  hideBalance?: boolean;
  pair?: Pair | null;
  hideInput?: boolean;
  otherCurrency?: Currency | null;
  id: string;
  showCommonBases?: boolean;
}

export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  label = "Input",
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  pair = null, // used for double token logo
  hideInput = false,
  otherCurrency,
  id,
  showCommonBases,
}: CurrencyInputPanelProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const { address } = useAccount();

  const selectedCurrencyBalance = useCurrencyBalance(
    address ?? undefined,
    currency ?? undefined
  );

  const theme = useContext(ThemeContext);

  const handleDismissSearch = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  return (
    <InputPanel id={id}>
      <Container>
        <InputRow
          style={hideInput ? { padding: "0", borderRadius: "8px" } : {}}
        >
          {!hideInput && (
            <>
              <StyledNumericalInput
                className="token-amount-input"
                value={value}
                onUserInput={(val) => {
                  onUserInput(val);
                }}
              />
            </>
          )}
          <CurrencySelector
            currency={currency}
            pair={pair}
            onClick={() => {
              if (!disableCurrencySelect) {
                setModalOpen(true);
              }
            }}
          />
        </InputRow>
        {!hideInput && (
          <LabelRow>
            {address && (
              <Row style={{ marginLeft: "auto", width: "unset" }}>
                <TYPE.Body
                  onClick={onMax}
                  color={theme?.text2}
                  fontWeight={400}
                  fontSize={14}
                  style={{ display: "inline", cursor: "pointer" }}
                >
                  {!hideBalance && !!currency && selectedCurrencyBalance
                    ? "balance: " + selectedCurrencyBalance?.toSignificant(6)
                    : " -"}
                </TYPE.Body>
                {address && currency && label !== "To" && (
                  <StyledBalanceMax onClick={onMax}>max</StyledBalanceMax>
                )}
              </Row>
            )}
          </LabelRow>
        )}
      </Container>
      {!disableCurrencySelect && onCurrencySelect && (
        <CurrencySearchModal
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          onCurrencySelect={onCurrencySelect}
          selectedCurrency={currency}
          otherSelectedCurrency={otherCurrency}
          showCommonBases={showCommonBases}
        />
      )}
    </InputPanel>
  );
}
